import React, { useEffect,useState } from "react";
// import './assets/css/style.css';
import "./assets/css/style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "swiper/css"; // Core CSS
import "swiper/css/autoplay"; // Autoplay styles
import "swiper/css/pagination"; // Pagination styles

import { Autoplay, Pagination } from "swiper/modules"; // Swiper modules

import "line-awesome/dist/line-awesome/css/line-awesome.min.css";

// import "magnific-popup/dist/magnific-popup.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Components/Home/Home"; // Example component
import AboutUs from "./Components/AboutUs/AboutUs";
import ContactUs from "./Components/ContactUs/ContactUs";
import JobPosting from "./Components/JobPosting/JobPosting";
// import PublisingSolutions from './Components/PublisingSolutions/PublisingSolutions';
import PublisingSolutions from "./Components/PublisingSolutions/PublisingSolutions";
import RecruitmentServices from "./Components/RecruitmentServices/RecruitmentServices";
import SapHCM from "./Components/SapHCM/SapHCM";
import SoftSkills from "./Components/SoftSkills/SoftSkills";
import Navbar from "./Components/Navbar/Navbar";


function App() {
  const [isLoading, setLoading] = useState(true);
  function fakeRequest() {
    return new Promise((resolve) => setTimeout(() => resolve(), 1000));
  }
  useEffect(() => {
    fakeRequest().then(() => {
      setLoading(false); // Set loading to false when loading is complete.
    });
  }, []);
  if (isLoading) {
    return (
      <div class="preloader" id="preloader">
        <div class="preloader-inner">
          <div class="spinner">
            <div class="dot1"></div>
            <div class="dot2"></div>
          </div>
        </div>
      </div>
    );
  }
 
  return (
    <Router>
      <div>
        {/* <Navbar/> */}

        {/* Add a navbar or header here if needed */}
        <Routes>
          {/* Define routes for your components */}
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Home />} />

          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/softSkills" element={<SoftSkills />} />
          <Route path="/sapHCM" element={<SapHCM />} />
          <Route
            path="/recruitmentServices"
            element={<RecruitmentServices />}
          />
          <Route path="/jobPosting" element={<JobPosting />} />
          <Route path="/publisingSolutions" element={<PublisingSolutions />} />

          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
