import React from "react";
import "./RecruitmentServices.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import FooterTwo from "../Footer2/FooterTwo";
import image1 from "../../assets/img/web_asstes/Recruitment Services.png"

const RecruitmentServices = () => {
  return (
    <div>
      {/* <!-- preloader area start -->/ */}
      {/* <div class="preloader" id="preloader">
        <div class="preloader-inner">
          <div class="spinner">
            <div class="dot1"></div>
            <div class="dot2"></div>
          </div>
        </div>
      </div> */}
      {/* <!-- preloader area end --> */}

      {/* <!-- search Popup --> */}
      <div class="body-overlay" id="body-overlay"></div>
      <div class="search-popup" id="search-popup">
        <form
          action="http://zwin.io/html/riyaqas/index.html"
          class="search-form"
        >
          <div class="form-group">
            <input type="text" class="form-control" placeholder="Search....." />
          </div>
          <button type="submit" class="submit-btn">
            <i class="fa fa-search"></i>
          </button>
        </form>
      </div>
      {/* <!-- //. search Popup --> */}

      {/* <!-- navbar area start --> */}
     <Navbar/>
      {/* <!-- navbar area end --> */}

      <div class="container Publishing-Sol-content ">
        <br/>
        <br/>
        <div class="section-title style-two mt-lg-0 mt-5 py-5">
          <h2 class="title text-center ">
            Recruitment<span> Services</span>
          </h2>
        </div>
        <div class=" d-flex justify-content-around flex-wrap mt-5">
          <div
            class="ebooks col-lg-6 col-12 order-lg-1 order-5 d-flex  align-items-end wow animated fadeInLeft"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            {" "}
            <div class="section-title style-two mt-lg-0 mt-3">
              <h4 class="title col-10 p-0 m-0 ">
                What Are <span>Recruitment Services</span>
              </h4>
              <p class="col-11 p-0 m-0 ">
                Recruitment services involve a comprehensive process to identify
                and hire the best talent for organizations. Technology screening
                ensures candidates have the technical skills required for
                specific roles by evaluating their proficiency in relevant tools
                and technologies. Preliminary screening involves assessing
                resumes and conducting initial interviews to shortlist
                candidates who meet the basic job criteria. Checkpoints are
                established throughout the hiring process to verify candidates'
                qualifications, experience, and cultural fit. Client-based
                screening tailors the recruitment process according to the
                specific needs and preferences of the hiring organization.
                Additionally, building a talent pool of qualified candidates
                allows companies to fill future roles more quickly and
                efficiently, creating a strategic advantage in talent
                acquisition.
              </p>

              <a
                href="#Recruitment-Services"
                class="explore-btn text-white d-flex justify-content-center align-items-center"
              >
                Explore <i class="fa fa-car"></i>
              </a>
            </div>{" "}
          </div>
          <div class="col-lg-6 col-12  order-lg-5 order-1 d-flex justify-content-center align-items-satrt">
            <div class="company-img ">
              {" "}
              <div
                class=" wow animated fadeInRight"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <img
                  class="object-fit-contain p-4 w-100"
                  src={image1}
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          class="section-title style-two mt-lg-0 mt-3"
          id="Recruitment-Services"
        >
          <h5 class="title text-center ">
            Recruitment<span> Services</span>
          </h5>
        </div>

        <div class="Recruitment-Services">
          <div class="row m-0 p-0 skills gap-3 d-flex justify-content-around  align-items-center flex-wrap ">
            <div class="Recruitment-Service p-4 mt-3 col-lg-6 col-12">
              <h5 class="box-heading">
                <span>1.Technology Screening</span>
              </h5>
              <p>
                At HR Pundits, we go beyond simple resume screening; we assess
                true potential. Our technology recruiters are not just
                gatekeepers—they are industry experts who thoroughly evaluate
                each candidate’s technical skills to ensure they meet and exceed
                your project’s requirements. We don’t just tick boxes; we delve
                into the depth of a candidate’s experience, aligning their
                strengths with the specific technological needs of your
                business.
              </p>
            </div>
            <div class="Recruitment-Service p-4 mt-3 col-lg-6 col-12">
              <h5 class="box-heading">
                <span>2.Preliminary Screening</span>
              </h5>
              <p>
                Our technology screening process is thorough and targeted. We
                don’t merely skim resumes; we dive into the technical expertise
                that aligns with your needs. At HR Pundits, our recruiters are
                adept at identifying the specific technical skills required for
                your roles, ensuring a precise match. Only candidates who meet
                your technical benchmarks and show promise for future growth
                advance, optimizing both time and quality in your hiring
                process.
              </p>
            </div>
            <div class="Recruitment-Service p-4 mt-3 col-lg-6 col-12">
              <h5 class="box-heading">
                <span>3.Checkpoints</span>
              </h5>
              <p>
                At HR Pundits, we recognize that each client operates in a
                distinct environment, so we create customized checkpoints that
                go beyond a basic checklist. These checkpoints are strategic
                touchpoints designed to align candidates with your company's
                culture, technical standards, and long-term objectives. Our
                recruiters dig deeper, ensuring every candidate is not only
                technically skilled but also a perfect fit for your
                organization's future growth.
              </p>
            </div>
            <div class="Recruitment-Service p-4 mt-3 col-lg-6 col-12">
              <h5 class="box-heading">
                <span>4.Client-Based Screening</span>
              </h5>
              <p>
                At HR Pundits, we customize our screening to fit your unique
                needs, ensuring that every candidate matches your specific
                criteria. Whether you value innovative problem-solving,
                leadership potential, or technical expertise, our tailored
                approach guarantees that candidates—from entry-level to senior
                leadership—meet your exact requirements. This personalized
                method ensures a perfect fit for your organization’s success.
              </p>
            </div>
            <div class="Recruitment-Service p-4 mt-3 col-lg-8 col-12">
              <h5 class="box-heading">
                <span>5.Talent Pool</span>
              </h5>
              <p>
                Our talent pool is both extensive and dynamic, continuously
                evolving with the latest technological trends. We maintain a
                network of top-tier candidates in the most in-demand fields,
                ensuring we are always ready to meet your needs. Our recruiters
                are experts and lifelong learners who stay at the forefront of
                technology, allowing us to provide candidates proficient in a
                wide range of skills.
              </p>
            </div>
          </div>
        </div>

        <div class="six-skills container mt-4" id="six-Soft-Skills">
  <div class="row m-0 p-0 skills gap-4 d-flex justify-content-around align-items-center flex-wrap">
    <div class="col-md-3 col-sm-6 col-12 skill p-4 mt-4">
      <h5 class="box-heading">
        <span>Cloud Technologies</span>
      </h5>
      <ul>
        <li>AWS</li>
        <li>Azure</li>
        <li>Google Cloud Platform (GCP)</li>
        <li>IBM Cloud</li>
      </ul>
    </div>
    <div class="col-md-3 col-sm-6 col-12 skill p-4 mt-4">
      <h5 class="box-heading">
        <span>Data Engineering</span>
      </h5>
      <ul>
        <li>Apache Hadoop</li>
        <li>Apache Spark</li>
        <li>Kafka</li>
        <li>Data Warehousing</li>
      </ul>
    </div>
    <div class="col-md-3 col-sm-6 col-12 skill p-4 mt-4">
      <h5 class="box-heading">
        <span>DevOps</span>
      </h5>
      <ul>
        <li>Docker</li>
        <li>Kubernetes</li>
        <li>Jenkins</li>
        <li>Ansible</li>
        <li>Terraform</li>
        <li>CI/CD pipelines</li>
      </ul>
    </div>
    <div class="col-md-3 col-sm-6 col-12 skill p-4 mt-4">
      <h5 class="box-heading">
        <span>Software Development</span>
      </h5>
      <ul>
        <li>Java</li>
        <li>Python</li>
        <li>.NET</li>
        <li>JavaScript frameworks (React, Angular, Vue.js)</li>
      </ul>
    </div>
    <div class="col-md-3 col-sm-6 col-12 skill p-4 mt-4">
      <h5 class="box-heading">
        <span>Database Management</span>
      </h5>
      <ul>
        <li>Oracle</li>
        <li>MongoDB</li>
        <li>Aurora DBA</li>
        <li>PostgreSQL</li>
        <li>Microsoft SQL Server</li>
      </ul>
    </div>
    <div class="col-md-3 col-sm-6 col-12 skill p-4 mt-4">
      <h5 class="box-heading">
        <span>Automation & Integration</span>
      </h5>
      <ul>
        <li>Stonebranch</li>
        <li>UiPath</li>
        <li>Blue Prism</li>
        <li>Selenium</li>
        <li>Mulesoft</li>
      </ul>
    </div>
    <div class="col-md-3 col-sm-6 col-12 skill p-4 mt-4">
      <h5 class="box-heading">
        <span>Cybersecurity</span>
      </h5>
      <ul>
        <li>Palo Alto Networks</li>
        <li>Cisco Secure</li>
        <li>Fortinet, Splunk</li>
        <li>CyberArk</li>
      </ul>
    </div>
    <div class="col-md-3 col-sm-6 col-12 skill p-4 mt-4">
      <h5 class="box-heading">
        <span>Artificial Intelligence & Machine Learning</span>
      </h5>
      <ul>
        <li>TensorFlow</li>
        <li>PyTorch</li>
        <li>Scikit-learn</li>
        <li>OpenAI</li>
        <li>IBM Watson</li>
      </ul>
    </div>
    <div class="col-md-3 col-sm-6 col-12 skill p-4 mt-4">
      <h5 class="box-heading">
        <span>Big Data Technologies</span>
      </h5>
      <ul>
        <li>Apache Cassandra</li>
        <li>Elasticsearch</li>
        <li>Redis</li>
        <li>Apache Flink</li>
        <li>HBase</li>
      </ul>
    </div>
    <div class="col-md-3 col-sm-6 col-12 skill p-4 mt-4">
      <h5 class="box-heading">
        <span>Enterprise Resource Planning (ERP)</span>
      </h5>
      <ul>
        <li>SAP</li>
        <li>Oracle ERP</li>
        <li>Microsoft Dynamics 365</li>
        <li>Workday</li>
        <li>Infor</li>
      </ul>
    </div>
    <div class="col-md-3 col-sm-6 col-12 skill p-4 mt-4">
      <h5 class="box-heading">
        <span>Mobile Development</span>
      </h5>
      <ul>
        <li>Swift (iOS)</li>
        <li>Kotlin (Android)</li>
        <li>React Native</li>
        <li>Flutter</li>
        <li>Xamarin</li>
      </ul>
    </div>
    <div class="col-md-3 col-sm-6 col-12 skill p-4 mt-4">
      <h5 class="box-heading">
        <span>Networking</span>
      </h5>
      <ul>
        <li>Cisco Networking (CCNA, CCNP)</li>
        <li>Juniper Networks</li>
        <li>Arista Networks</li>
        <li>Palo Alto Networks</li>
        <li>Fortinet</li>
      </ul>
    </div>
    <div class="col-md-3 col-sm-6 col-12 skill p-4 mt-4">
      <h5 class="box-heading">
        <span>Infrastructure as Code (IaC)</span>
      </h5>
      <ul>
        <li>Terraform</li>
        <li>CloudFormation</li>
        <li>Pulumi</li>
        <li>Ansible</li>
        <li>Chef</li>
      </ul>
    </div>
    <div class="col-md-3 col-sm-6 col-12 skill p-4 mt-4">
      <h5 class="box-heading">
        <span>Virtualization & Containerization</span>
      </h5>
      <ul>
        <li>VMware</li>
        <li>Docker</li>
        <li>OpenShift</li>
        <li>LXC/LXD</li>
        <li>Parallels</li>
      </ul>
    </div>
    <div class="col-md-3 col-sm-6 col-12 skill p-4 mt-4">
      <h5 class="box-heading">
        <span>Business Intelligence</span>
      </h5>
      <ul>
        <li>Tableau</li>
        <li>Power BI</li>
        <li>Qlik</li>
        <li>Domo</li>
        <li>Looker</li>
      </ul>
    </div>
  </div>
</div>


        {/* <!--    <div class=" d-flex justify-content-around flex-wrap mt-5">-->
    <!--<div class="ebooks col-lg-6 col-12 order-lg-1 order-5 d-flex  align-items-end wow animated fadeInLeft" data-wow-duration="1s" data-wow-delay="0.3s"> <div class="section-title style-two mt-lg-0 mt-3">-->
    <!--    <h5 class="title"><span>Prowess</span> Publishing solutions</h5>-->
    <!--    <p class="col-11 p-0 m-0 mt-2">Established as a division of US-based Prowess Technologies, LLC, Prowess Publishing has over 14 years of experience delivering innovative solutions to publishing and media companies worldwide. Since 2011, we have helped hundreds of US authors launch their careers and now bring the same expertise to the self-publishing community in India. Formed by Gantec Publishing Solutions, Prowess offers a multitude of services to help Indian authors succeed globally. Our unique approach supports authors at every stage, from manuscript submission to distribution, specializing in eBook conversions and offering marketing assistance, cover design, and distribution through top retailers like Amazon and Apple.</p>-->
      
    <!--     <a href="https://www.prowesspub.com" class="explore-btn text-white d-flex justify-content-center align-items-center">Explore <i class="fa fa-car"></i></a>-->
    <!--</div> </div>-->
    <!--<div class="col-lg-6 col-12  order-lg-5 order-1 ">-->
    <!--    <div class="company-img "> <div class=" wow animated fadeInRight" data-wow-duration="1s" data-wow-delay="0.3s">-->
    <!--        <img class="object-fit-contain p-4 w-100" src="assets/img/client/Prowess-partner.png" alt="img">-->
    <!--    </div></div>-->
    <!--</div>--> */}

        {/* <!-- footer area start --> */}
        {/* <footer class="footer-area footer-area-2 mg-top-105">
          <div class="container">
            <div class="footer-widget-area mg-top-120">
              <div class="row">
                <div class="col-lg-3 col-md-6">
                  <div class="footer-widget widget">
                    <div class="about_us_widget">
                      <a href="#" class="footer-logo">
                        <img src="images/logo.png" alt="footer logo" />
                      </a>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy.
                      </p>
                      <ul class="social-icon">
                        <li>
                          <a class="facebook" href="#" target="_blank">
                            <i class="fa fa-facebook  "></i>
                          </a>
                        </li>
                        <li>
                          <a class="twitter" href="#" target="_blank">
                            <i class="fa fa-twitter  "></i>
                          </a>
                        </li>
                        <li>
                          <a class="linkedin" href="#" target="_blank">
                            <i class="fa fa-linkedin"></i>
                          </a>
                        </li>
                        <li>
                          <a class="pinterest" href="#" target="_blank">
                            <i class="fa fa-instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-lg-2 col-md-6">
                  <div class="footer-widget widget ">
                    <h4 class="widget-title">Contact</h4>
                    <div class="contact_info_list">
                      <p class="contact-content">
                        1827 Walden office Sq Suite # 260 Schaumburg, IL- 60173.
                      </p>
                      <p>
                        <span>Contact:</span> 847 598 1122
                      </p>
                      <p>
                        <span>E-mail:</span> info@hrpundits.com
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-2 col-md-6">
                  <div class="footer-widget widget widget_nav_menu">
                    <h4 class="widget-title">Quick Link</h4>
                    <ul>
                      <li>
                        <a href="#">Talent Management</a>
                      </li>
                      <li>
                        <a href="#">Application Development</a>
                      </li>
                      <li>
                        <a href="#">ERP</a>
                      </li>
                      <li>
                        <a href="#">Training</a>
                      </li>
                      <li>
                        <a href="#">Corporate Training</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-2 col-md-6">
                  <div class="footer-widget widget widget_nav_menu">
                    <h4 class="widget-title">Useful Links</h4>

                    <ul>
                      <li>
                        <a
                          href="http://www12.sap.com/index.epx"
                          target="_blank"
                        >
                          SAP.com
                        </a>
                      </li>
                      <li>
                        <a href="http://help.sap.com/" target="_blank">
                          help.SAP.com
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://websmp205.sap-ag.de/hrusa"
                          target="_blank"
                        >
                          SAP Marketplace
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://www.sap.com/usa/services/education/certification/index.epx"
                          target="_blank"
                        >
                          SAP HCM Certification
                        </a>
                      </li>
                      <li>
                        <a href="http://www.paycheckcity.com/" target="_blank">
                          Payroll Calculation
                        </a>
                      </li>
                      <li>
                        <a href="http://www.asug.com/" target="_blank">
                          ASUG
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://66.147.244.204/~hrpundit/index.php?option=com_content&amp;view=article&amp;id=64"
                          target="_blank"
                        >
                          Common Job Sites
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="footer-widget widget">
                    <h4 class="widget-title">Latest News</h4>
                    <div class="about_recent_post">
                      <div class="media">
                        <img src="assets/img/blog/widget1.png" alt="post" />
                        <div class="media-body">
                          <h6>
                            <a href="#">New Feature Added</a>
                          </h6>
                          <span>21 August 2019</span>
                        </div>
                      </div>
                      <div class="media">
                        <img src="assets/img/blog/widget2.png" alt="post" />
                        <div class="media-body">
                          <h6>
                            <a href="#">Business Development</a>
                          </h6>
                          <span>21 August 2019</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="copyright-inner">
            <div class="copyright-text">
              Copyright © 2023. HR Pundits. All rights reserved.
            </div>
          </div>
        </footer> */}
        <FooterTwo/>
        {/* <!-- footer area end -->




<!-- back to top area start --> */}
        <div class="back-to-top">
          <span class="back-top">
            <i class="fa fa-angle-up"></i>
          </span>
        </div>
      </div>
    </div>
  );
};

export default RecruitmentServices;
