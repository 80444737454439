import React from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../images/logo.png";
import "./Navbar.css";
import "../../assets/css/style.css";

const Navbar = () => {
  const [isSticky, setIsSticky] = React.useState(false);
  const location = useLocation();

  React.useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const isActive = (path) => (location.pathname === path ? "active" : "");
  const isParentActive = (paths) =>
    paths.some((path) => location.pathname.startsWith(path)) ? "active" : "";

  return (
    <div>
      <nav
        className={`navbar navbar-area navbar-expand-lg nav-style-01 ${
          isSticky ? "navbar-area-fixed" : ""
        }`}
      >
        <div
          className="container nav-container"
          style={{ marginLeft: "100px" }}
        >
          <div className="responsive-mobile-menu">
            <div className="logo-wrapper mobile-logo">
              <a href="/" className="logo">
                <img src={logo} alt="logo" />
              </a>
            </div>

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#Riyaqas_main_menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggle-icon">
                <span className="line"></span>
                <span className="line"></span>
                <span className="line"></span>
              </span>
            </button>
          </div>

          <div className="collapse navbar-collapse" id="Riyaqas_main_menu">
            <div className="logo-wrapper desktop-logo">
              <a href="/" className="logo">
                <img src={logo} alt="logo" />
              </a>
            </div>
            <ul className="navbar-nav">
              <li>
                <a href="/" className={isActive("/")}>
                  Home
                </a>
              </li>
              <li className="menu-item-has-children">
                <a
                  className={`${isParentActive(["/recruitmentServices"])}`}
                  href="#"
                >
                  Talent Management
                </a>
                <ul className="sub-menu">
                  <li>
                    <a
                      href="/recruitmentServices"
                      className={isActive("/recruitmentServices")}
                    >
                      Recruitment Services
                    </a>
                  </li>
                  <li>
                    <a href="#">HR Services</a>
                  </li>
                </ul>
              </li>

              <li className="menu-item-has-children">
                <a href="#">Application Development</a>
                <ul className="sub-menu">
                  <li>
                    <a href="#">Websites / Mobile APPS</a>
                  </li>
                  <li>
                    <a href="#">Software Development</a>
                  </li>
                </ul>
              </li>

              <li>
                <a href="/jobPosting" className={isActive("/jobPosting")}>
                  Jobs
                </a>
              </li>

              <li className="menu-item-has-children">
                <a
                  className={`${isParentActive([
                    "/sapHCM",
                    "/publishingSolutions",
                    "/softSkills"
                  ])}`}
                  href="#"
                >
                  Other Services
                </a>
                <ul className="sub-menu">
                  <li className="menu-item-has-children">
                    <a href="#">Training</a>
                    <ul className="sub-menu">
                      <li>
                        <a href="#">Corporate Training</a>
                      </li>
                      <li>
                        <a href="/sapHCM" className={isActive("/sapHCM")}>
                          SAP HCM & SuccessFactor
                        </a>
                      </li>
                      <li className="menu-item-has-children">
                        <a className="content" href="#">
                          Other Trainings{" "}
                        </a>
                        <ul className="sub-menu">
                          <li>
                            <a
                              href="https://training.hrpundits.com/business-analyst115569"
                              target="_blank"
                            >
                              Business Analyst
                            </a>
                          </li>
                          
                         
                          <li>
                            <a href="#">
                              Data Analyst{" "}
                              <span style={{ fontSize: "9px" }}>
                                (Coming Soon)
                              </span>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              Data Engineering
                              <span style={{ fontSize: "9px" }}>
                                (Coming Soon)
                              </span>{" "}
                            </a>
                          </li>
                          <li>
                            <a
                              href="/softSkills"
                              className={isActive("/softSkills")}
                            >
                              Soft Skills
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                      <a href="https://upskillgenius.com/" target="_blank">Online Courses</a>
                    </li>
                    </ul>
                  </li>

                  <li>
                    <a
                      href="/publishingSolutions"
                      className={isActive("/publishingSolutions")}
                    >
                      Publishing Solutions
                    </a>
                  </li>
                 
                  <li>
                    <a href="#">Media</a>
                  </li>
                  <li>
                    <a href="#">Community Services</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="/contact" className={isActive("/contact")}>
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
