import React from "react";
import logo from "../../images/logo.png";
import image1 from "../../assets/img/blog/widget1.png";
import image2 from "../../assets/img/blog/widget2.png";

const FooterTwo = () => {
  return (
    <div>
      <footer class="footer-area footer-area-2 ">
        <div class="container">
          <div class="footer-widget-area mg-top-120">
            <div class="row">
              <div class="col-lg-3 col-md-6">
                <div class="footer-widget widget">
                  <div class="about_us_widget">
                    <a href="#" class="footer-logo">
                      <img src={logo} alt="footer logo" />
                    </a>
                    <p>
                      Established in 2005 as Minority and Small Business
                      Enterprise in the State of Illinois, HR Pundits today is
                      one of the leading solution providers of SAP ERP HCM
                      Technology. 
                    </p>
                    <ul class="social-icon">
                      <li>
                        <a class="facebook" href="https://www.facebook.com/profile.php?id=100063794947852#" target="_blank">
                          <i class="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a class="twitter" href="https://x.com/i/flow/login?redirect_after_login=%2FHRPu_SAP_HR_HCM" target="_blank">
                          <i class="fa fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a class="linkedin" href="https://www.linkedin.com/company/hr-pundits-inc---sap-hr-hcm-training-and-projects/?trk=fc_badge" target="_blank">
                          <i class="fa fa-linkedin"></i>
                        </a>
                      </li>
                      {/*<li>
                        <a class="pinterest" href="#" target="_blank">
                          <i class="fa fa-instagram"></i>
                        </a>
                      </li>*/}
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="footer-widget widget">
                  <h4 class="widget-title">Contact</h4>
                  <div class="contact_info_list">
                    <p class="contact-content">
                      1827 Walden office Sq Suite # 260 Schaumburg, IL- 60173.
                    </p>
                    <p>
                      <span>Contact:</span> 847 598 1122
                    </p>
                    <p>
                      <span>E-mail:</span> info@hrpundits.com
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="footer-widget widget widget_nav_menu">
                  <h4 class="widget-title">Quick Link</h4>
                  <ul>
                    <li>
                      <a href="#">Talent Management</a>
                    </li>
                    <li>
                      <a href="#">Application Development</a>
                    </li>
                    <li>
                      <a href="#">ERP</a>
                    </li>
                    <li>
                      <a href="#">Training</a>
                    </li>
                    <li>
                      <a href="#">Corporate Training</a>
                    </li>
                  </ul>
                </div>
              </div>
             {/* <div class="col-lg-2 col-md-6">
                <div class="footer-widget widget widget_nav_menu">
                  <h4 class="widget-title">Useful Links</h4>

                  <ul>
                    <li>
                      <a href="http://www12.sap.com/index.epx" target="_blank">
                        SAP.com
                      </a>
                    </li>
                    <li>
                      <a href="http://help.sap.com/" target="_blank">
                        help.SAP.com
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://websmp205.sap-ag.de/hrusa"
                        target="_blank"
                      >
                        SAP Marketplace
                      </a>
                    </li>
                    <li>
                      <a
                        href="http://www.sap.com/usa/services/education/certification/index.epx"
                        target="_blank"
                      >
                        SAP HCM Certification
                      </a>
                    </li>
                    <li>
                      <a href="http://www.paycheckcity.com/" target="_blank">
                        Payroll Calculation
                      </a>
                    </li>
                    <li>
                      <a href="http://www.asug.com/" target="_blank">
                        ASUG
                      </a>
                    </li>
                    <li>
                      <a
                        href="http://66.147.244.204/~hrpundit/index.php?option=com_content&amp;view=article&amp;id=64"
                        target="_blank"
                      >
                        Common Job Sites
                      </a>
                    </li>
                  </ul>
                </div>
              </div>*/}
              <div class="col-lg-3 col-md-6">
                <div class="footer-widget widget">
                  <h4 class="widget-title">Latest News</h4>
                  <div class="about_recent_post">
                    <div class="media">
                      <img src={image1} alt="post" />
                      <div class="media-body">
                        <h6>
                          <a href="#">New Feature Added</a>
                        </h6>
                        <span>21 August 2019</span>
                      </div>
                    </div>
                    <div class="media">
                      <img src={image2} alt="post" />
                      <div class="media-body">
                        <h6>
                          <a href="#">Business Development</a>
                        </h6>
                        <span>21 August 2019</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="copyright-inner">
          <div class="copyright-text">
            Copyright © 2023. HR Pundits. All rights reserved.
          </div>
        </div>
      </footer>
    </div>
  );
};

export default FooterTwo;
