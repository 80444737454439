import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Button, Modal, Spin, ConfigProvider } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import { Select, Space } from "antd";
import axios from "axios";
const { Dragger } = Upload;
const Server = process.env.REACT_APP_SERVER;

const ApplyModal = ({ isModalOpen, setIsModalOpen, postedJobTitle, jobId }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [city, SetCity] = useState("");
  const [country, SetCountry] = useState("");
  const [countryData, SetCountryData] = useState([]);
  const [cityData, SetCityData] = useState([]);
  const [state, setState] = useState(""); // Add state
  const [stateData, setStateData] = useState([]); // Add state data
  const [resume, setResume] = useState("");
  const [jobTitle, setjobTitle] = useState("");
  const [experience, setExperience] = useState("");
  const [applied, setApplied] = useState("");
  const [resumeData, setResumeData] = useState([]);
  const [loader, setloader] = useState(false);

  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    jobTitle: "",
    resume: "",
    experience: "",
    country: "",
    city: "",
    state: "",
  });
  // console.log(jobModalOpen.jobData);
  const validateForm = () => {
    const errors = {};

    if (firstName.trim() === "") {
      errors.firstName = "FirstName is required";
    }
    if (email.trim() === "") {
      errors.email = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      errors.email = "Invalid email format";
    }
    if (lastName.trim() === "") {
      errors.lastName = "LastName is required";
    }
    if (phoneNumber.trim() === "") {
      errors.phoneNumber = "Phone Number is required";
    }
    if (experience.trim() === "") {
      errors.experience = "Experience is required";
    }
    if (jobTitle.trim() === "") {
      errors.jobTitle = "Job Title is required";
    }
    if (country.trim() === "") {
      errors.country = "Country is required";
    }
    if (state.trim() === "") {
      errors.state = "Province/State is required";
    }
    if (city.trim() === "") {
      errors.city = "City is required";
    }

    setError(errors);

    return Object.keys(errors).length === 0; // Returns true if no errors
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  //  console.log(jobModalOpen.jobModalOpen);
  const props = {
    name: "file",
    multiple: true,
    beforeUpload: (file) => {
      setResumeData((prev) => [...prev, file]); // Store the file in state
      return false; // Prevent auto-upload, handle manually if needed
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log("Selected Files:", resumeData);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    validateForm();
    if (resumeData.length === 0) {
      toast.error("Upload Resume");
      return;
    }
    try {
      if (validateForm()) {
        setloader(true);
        let basicDetails = {
          firstName,
          lastName,
          email,
          phoneNumber,
          country,
          state,
          city,
          experience,
          jobTitle,
          // postedJobTitle,
          // skills,
          // jobId,
        };
        const formData = new FormData();
        formData.append("file", resumeData[0]);
        formData.append("lastName", lastName);
        formData.append("firstName", firstName);
        formData.append("email", email);
        formData.append("country", country);
        formData.append("phoneNumber", phoneNumber);
        formData.append("jobTitle", jobTitle);
        formData.append("experience", experience);
        formData.append("state", state);
        formData.append("city", city);
        formData.append("postedJobTitle", postedJobTitle);
        formData.append("jobId", jobId);

        console.log(formData);
        const { data } = await axios.post(
          `${process.env.REACT_APP_SERVER}/api/jobs/candidate/apply`,
          formData
        );
        console.log(data);
        toast.success(data.message);
        setloader(false);

        if (data.message === "Application Submitted Successfully") {
          setloader(false);
          setTimeout(() => {
            setIsModalOpen(false);
          }, 500);
        } else {
          setloader(false);
          setTimeout(() => {
            setIsModalOpen(false);
          }, 500);
        }
      }
    } catch (error) {
      console.log(error);
      setloader(false);
      if (error?.response?.data) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong");
      }
    }
  };
  // console.log(phoneNumber);
  return (
    <div>
      <Modal
        centered
        open={isModalOpen}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Toaster position="top-center" reverseOrder={false} />

        <div class="modal-body ">
          <h3 className="text-center">Job Apply</h3>

          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Upload Resume</p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
          </Dragger>
          <span style={{ color: "red" }}>{error.resume}</span>
          <form
            id="inquiryForm"
            class="riyaqas-form-wrap mt-5 mt-lg-0"
            style={{ paddingTop: "20px" }}
            onSubmit={handleSubmit}
          >
            <div class="row custom-gutters-16">
              <div class="col-md-6">
                <div class="single-input-wrap">
                  <input
                    type="text"
                    id="name"
                    class="single-input"
                    // required
                    style={{ marginBottom: "10px",color:"#000"  }}
                    value={firstName}
                    placeholder="First name"
                    onChange={(e) => {
                      setFirstName(e.target.value);
                      setError((prev) => ({ ...prev, firstName: "" }));
                    }}
                  />
                  <span style={{ color: "red" }}>{error.firstName}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="single-input-wrap">
                  <input
                    type="text"
                    id="name"
                    class="single-input"
                    // required
                    style={{ marginBottom: "10px",color:"#000" }}
                    value={lastName}
                    placeholder="Last name"
                    onChange={(e) => {
                      setLastName(e.target.value);
                      setError((prev) => ({ ...prev, lastName: "" }));
                    }}
                  />
                  <span style={{ color: "red" }}>{error.lastName}</span>
                </div>
              </div>
              <div class="col-md-12">
                <div class="single-input-wrap">
                  <input
                    type="email"
                    id="email"
                    class="single-input"
                    // required
                    style={{ marginBottom: "10px",color:"#000" }}
                    placeholder="E-mail"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setError((prev) => ({ ...prev, email: "" }));
                    }}
                  />
                  <span style={{ color: "red" }}>{error.email}</span>
                </div>
              </div>
              <div class="col-md-12">
                <div class="single-input-wrap">
                  <input
                    type="text"
                    id="Phone Number"
                    class="single-input"
                    value={phoneNumber}
                    placeholder="Phone Number"
                    style={{ marginBottom: "10px",color:"#000"  }}
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                      setError((prev) => ({ ...prev, phoneNumber: "" }));
                    }}
                    // required
                  />
                  <span style={{ color: "red" }}>{error.phoneNumber}</span>
                </div>
              </div>
              <div class="col-md-12">
                <div class="single-input-wrap">
                  <input
                    class="single-input"
                    id="Your-Job -itle"
                    cols="20"
                    // required
                    placeholder="Your Job Title"
                    style={{ marginBottom: "10px",color:"#000"  }}
                    value={jobTitle}
                    onChange={(e) => {
                      setjobTitle(e.target.value);
                      setError((prev) => ({ ...prev, jobTitle: "" }));
                    }}
                  ></input>

                  <span style={{ color: "red" }}>{error.jobTitle}</span>
                </div>
              </div>
              <div class="col-md-12">
                <div class="single-input-wrap">
                  <input
                    class="single-input"
                    id="experience"
                    cols="20"
                    // required
                    placeholder="experience"
                    style={{ marginBottom: "10px",color:"#000"  }}
                    value={experience}
                    onChange={(e) => {
                      setExperience(e.target.value);
                      setError((prev) => ({ ...prev, experience: "" }));
                    }}
                  ></input>

                  <span style={{ color: "red" }}>{error.experience}</span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="single-input-wrap">
                  <input
                    class="single-input"
                    id="country"
                    cols="20"
                    // required
                    placeholder="country"
                    style={{ marginBottom: "10px",color:"#000"  }}
                    value={country}
                    onChange={(e) => {
                      SetCountry(e.target.value);
                      setError((prev) => ({ ...prev, country: "" }));
                    }}
                  ></input>

                  <span style={{ color: "red" }}>{error.country}</span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="single-input-wrap">
                  <input
                    class="single-input"
                    id="Province/State"
                    style={{ marginBottom: "10px",color:"#000"  }}
                    cols="20"
                    // required
                    placeholder="Province/State"
                    value={state}
                    onChange={(e) => {
                      setState(e.target.value);
                      setError((prev) => ({ ...prev, state: "" }));
                    }}
                  ></input>

                  <span style={{ color: "red" }}>{error.state}</span>
                </div>
              </div>
              <div class="col-md-4">
                <div class="single-input-wrap">
                  <input
                    class="single-input"
                    id="City"
                    cols="20"
                    style={{ marginBottom: "10px",color:"#000"  }}
                    // required
                    placeholder="City"
                    value={city}
                    onChange={(e) => {
                      SetCity(e.target.value);
                      setError((prev) => ({ ...prev, city: "" }));
                    }}
                  ></input>

                  <span style={{ color: "red" }}>{error.city}</span>
                </div>
              </div>
              <div class="col-12">
                <button
                  id="submitButton"
                  type="submit"
                  class="btn btn-red mt-0"
                    disabled={loader}
                >
                  {/*Submit*/}
                  {loader ? "Submitting..." : "Submit"}
                </button>
              </div>
            </div>
          </form>
          {/*<div class>
            <label className="head-nav">First Name</label>
            <input type="text" class="form-control" placeholder="First Name" />
          </div>   
          <div class>
            <label className="head-nav">Last Name</label>
            <input class="form-control" placeholder="Last Name" />
          </div>
          <div class>
            <label className="head-nav">Email</label>
            <input type="email" class="form-control" placeholder="Email" />
          </div>
          <div class>
            <label className="head-nav">Phone Number</label>
            <input type="tel" class="form-control" placeholder="Phone Number" />
          </div>
          <div>
            <label className="head-nav">Country</label>
            <select id="country" class="form-control" name="country">
              <option value>Select Country</option>
              <option value="Canada">Canada</option>
              <option value="United States">United States</option>
              <option value="United Kingdom">United Kingdom</option>
              <option value="Australia">Australia</option>
              <option value="India">India</option>
              <option value="Others">Others</option>
            </select>
          </div>
          <div class>
            <label className="head-nav">State</label>
            <select id="state" class="form-control" name="state">
              <option value>Select State</option>
              <option value="Ontario">Ontario</option>
              <option value="California">California</option>
              <option value="New York">New York</option>
              <option value="Queensland">Queensland</option>
              <option value="Maharashtra">Maharashtra</option>
              <option value="Others">Others</option>
            </select>
          </div>
          <div class>
            <label className="head-nav">City</label>
            <select id="city" name="city" class="form-control">
              <option value>Select City</option>
              <option value="Toronto">Toronto</option>
              <option value="Los Angeles">Los Angeles</option>
              <option value="New York City">New York City</option>
              <option value="Brisbane">Brisbane</option>
              <option value="Mumbai">Mumbai</option>
              <option value="Others">Others</option>
            </select>
          </div>

          <div class>
            <label className="head-nav">Job Title</label>
            <input type="text" class="form-control" placeholder="Job Title" />
          </div>
          <div>
            <label className="head-nav">Experience</label>
            <input type="text" class="form-control" placeholder="Experience" />
          </div>
          <br />
          <button type="submit" class="btn-blue subcribe-submit">
            submit
          </button>*/}
        </div>
      </Modal>
    </div>
  );
};

export default ApplyModal;
{
  /**const props = {
    name: "file",
    multiple: true,
    // action: `${Server}/api/v1/contact/email/submit/resume`,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        // console.log(info.file, info.fileList);
        // console.log(info?.fileList[0]?.response?.location);
      }
      if (status === "done") {
        setResume(info?.fileList[0]?.response?.location);
        setResumeData(info?.fileList[0]?.response?.data);
        setExperience(
          info?.fileList[0]?.response?.data?.basic_info?.Experience_in_Years
        );
        setjobTitle(info?.fileList[0]?.response?.data?.basic_info?.Job_Title);
        setEmail(info?.fileList[0]?.response?.data?.basic_info?.email);
        // setPhoneNumber(Number(info?.fileList[0]?.response?.data?.basic_info.phone_number));
        SetCountry(info?.fileList[0]?.response?.data?.basic_info?.Country);
        SetCity(info?.fileList[0]?.response?.data?.basic_info?.City);
        setState?.basic_info?.(
          info?.fileList[0]?.response?.data?.basic_info?.Province
        );
        // console.log(info?.fileList[0]?.response?.data?.basic_info);
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      // console.log("Dropped files", e.dataTransfer.files);
    },
  };
  // console.log(resumeData);
  const location = window.location.href;
  const employerId = location.split("/")[4];
  const jobId = location.split("/")[5];
  // console.log(location.split("/"));
  // console.log("hi");
  useEffect(() => {
    fetchCountries();
  }, []);
  useEffect(() => {
    if (country) {
      fetchCities();
    }
  }, [country]);
  // console.log(resume);
  const fetchCountries = async () => {
    try {
      const { data } = await axios.get(
        `https://countriesnow.space/api/v0.1/countries`
      );
      // console.log(data.country_city);
      SetCountryData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  // Fetch states based on selected country
  const fetchStates = async () => {
    try {
      const { data } = await axios.post(
        `https://countriesnow.space/api/v0.1/countries/states`,
        {
          country: country,
        }
      );
      setStateData(data.data.states); // Set state data
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (country) {
      fetchStates(); // Fetch states when country changes
    }
  }, [country]);
  useEffect(() => {
    if (state) {
      fetchCities(); // Fetch states when country changes
    }
  }, [state]);
  const fetchCities = async () => {
    try {
      const { data } = await axios.post(
        `https://countriesnow.space/api/v0.1/countries/state/cities`,
        {
          country: country,
          state: state,
        }
      );
      // console.log(data.country_city);
      SetCityData(data.data);
    } catch (error) {
      console.log(error);
    }
  }; // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const countryOptions = countryData.map((e, idx) => ({
    value: e.country,
    label: e.country,
  }));

  const stateOptions = country
    ? stateData?.map((e, idx) => ({
        value: e.name,
        label: e.name,
      }))
    : [{ value: null, label: "Select Country First" }];

  const cityOptions = state
    ? cityData.map((e, idx) => ({ value: e, label: e }))
    : [{ value: null, label: "Select State First" }];
     <div class="model">
          <div className="resume-modal">
            <h2>Job Apply</h2>
            <form className="form-resume">
              <div>
                <Dragger {...props}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Support for a single or bulk upload. Strictly prohibited
                    from uploading company data or other banned files.
                  </p>
                </Dragger>
                {/* <div class="uploading-resume" {...props}>
                  <div class="uploadButton">
                    <input
                      class="uploadButton-input"
                      type="file"
                      name="attachments[]"
                      accept="image/*, application/pdf"
                      id="upload"
                      // onChange={InputChange}
                      multiple
                    />
                    <label class="cv-uploadButton" for="upload">
                      <span class="title">Drop files here to upload</span>
                      <span class="text">
                        To upload file size is (Max 5Mb) and allowed file types
                        are (.doc, .docx, .pdf)
                      </span>
                    </label>
                  </div>
                </div>
                <div
                  className="mob-order"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "20px",
                  }}
                >
                  <div
                    style={{ display: "flex", gap: "20px" }}
                    className="mob-order1"
                  >
                    <div class="">
                      <label className="head-nav">First Name</label>
                      <input
                        type="text"
                        name="text"
                        placeholder="First Name"
                        className="form-group1"
                        onChange={(e) => setFirstName(e.target.value)}
                        value={firstName}
                        // required
                      />
                    </div>
  
                    <div class="">
                      <label className="head-nav">Last Name</label>
                      <input
                        type="text"
                        name="text"
                        placeholder="Last Name"
                        className="form-group1"
                        onChange={(e) => setLastName(e.target.value)}
                        value={lastName}
                        //   // required
                      />
                    </div>
                  </div>
                  <div class="">
                    <label className="head-nav">Email</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      className="form-group1"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      // required
                    />
                  </div>
  
                <div class="">
                    <label className="head-nav">Applied For</label>
                    <input
                      type="applied"
                      name="applied"
                      placeholder="Which role applied for"
                      className="form-group1"
                      onChange={(e) => setApplied(e.target.value)}
                      value={applied}
                      // required
                    />
                  </div>
  
                  <div class="">
                    <label className="head-nav">Job Title</label>
                    <input
                      type="jobTitle"
                      name="jobTitle"
                      placeholder="Job Title"
                      className="form-group1"
                      onChange={(e) => setjobTitle(e.target.value)}
                      value={jobTitle}
                      // required
                    />
                  </div>
  
                  <div class="">
                    <label className="head-nav">Total Experience</label>
                    <input
                      type="experience"
                      name="experience"
                      placeholder="Total Years of Experience (eg. 1yr 2months)"
                      className="form-group1"
                      onChange={(e) => setExperience(e.target.value)}
                      value={experience}
                      // required
                    />
                  </div>
  
                  <div class="">
                    <label className="head-nav">Phone Number</label>
                    <input
                      type="number"
                      name="phoneNumber"
                      placeholder="Phone Number"
                      className="form-group1"
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      value={phoneNumber}
                      // required
                    />
                  </div>
                  <div
                    style={{ display: "flex", gap: "10px" }}
                    className="mob-order1"
                  >
                    <div class="form-group col-lg-4 col-md-12">
                      <label>Country</label>
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                        style={{ width: "100%" }}
                        className="custom-select"
                        placeholder="Select Country"
                        options={countryOptions}
                        value={country}
                        // value={primaryIndustry}
                        onChange={(e) => SetCountry(e)}
                      />
                    </div>
                    <div class="form-group col-lg-4 col-md-12">
                      <label>State</label>
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        filterOption={filterOption}
                        className="custom-select"
                        placeholder="Select State"
                        options={stateOptions}
                        value={state}
                        onChange={(e) => setState(e)}
                      />
                    </div>
                    <div class="form-group col-lg-4 col-md-12">
                      <label>City</label>
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        filterOption={filterOption}
                        className="custom-select"
                        placeholder="Select City"
                        options={cityOptions}
                        value={city}
                        // value={primaryIndustry}
                        onChange={(e) => SetCity(e)}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    className="theme-btn btn-style-two"
                    type="submit"
                    //   onClick={handleSubmit}
                    disabled={loading}
                  >
                    <span className="submit-resume">
                      {loading ? (
                        <ConfigProvider
                          theme={{
                            token: {
                              colorPrimary: "#ffff",
                            },
                          }}
                        >
                          <Spin />
                        </ConfigProvider>
                      ) : (
                        "Apply"
                      )}
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
 */
}
