import React, { useState, useEffect } from "react";
import "./JobPosting.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import FooterTwo from "../Footer2/FooterTwo";
import Image1 from "../../assets/img/web_asstes/job-posting.png";
import Image2 from "../../";
import Image3 from "../../";
import Image4 from "../../";
import Image5 from "../../";
import Image6 from "../../";
import Image7 from "../../";
import axios from "axios";
import ApplyModal from "../ApplyModal/ApplyModal";
const JobPosting = () => {
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [searchTitle, setSearchTitle] = useState("");
  const [searchLocation, setSearchLocation] = useState("");
  const [isJobselected, setIsJobSlected] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // filter jobs when user click on search jobs button
  const filterJobs = () => {
    console.log(searchTitle, searchLocation);

    if (!searchTitle && !searchLocation) {
      setFilteredJobs(jobs);
      return;
    }

    const filtered = jobs.filter(
      (job) =>
        job.jobTitle.toLowerCase().includes(searchTitle.toLowerCase()) &&
        job.jobLocation.toLowerCase().includes(searchLocation.toLowerCase())
    );

    setFilteredJobs(filtered);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  useEffect(() => {
    getJobs();
  }, []);

  const getJobs = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_SERVER}/api/jobs/get/allJobs`
      );
      setJobs(data.jobs);
      setFilteredJobs(data.jobs);
    } catch (error) {
      console.log(error);
    }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  const showJobDetails = (job) => {
    setSelectedJob(job);
    setIsJobSlected(true);
  };
  const toggleDescription = (id) => {
    const moreContent = document.getElementById(`more-${id}`);
    const readMoreBtn = document.getElementById(`read-more-btn-${id}`);

    if (moreContent.style.display === "none") {
      moreContent.style.display = "block";
      readMoreBtn.innerText = "Read Less";
    } else {
      moreContent.style.display = "none";
      readMoreBtn.innerText = "Read More";
    }
  };
  return (
    <div>
      <div class="body-overlay" id="body-overlay"></div>
      <div class="search-popup" id="search-popup">
        <form
          action="http://zwin.io/html/riyaqas/index.html"
          class="search-form"
        >
          <div class="form-group">
            <input type="text" class="form-control" placeholder="Search....." />
          </div>
          <button type="submit" class="submit-btn">
            <i class="fa fa-search"></i>
          </button>
        </form>
      </div>
      <Navbar />

      <div class="container Soft-skills-content  py-4">
        <div class="section-title style-two mt-lg-0 mt-3">
          <h2 class="title text-center ">
            Job<span> Postings</span>
          </h2>
        </div>
        <div class=" d-flex justify-content-around flex-wrap ">
          <div
            class="ebooks col-lg-6 col-12 order-lg-1 order-5 d-flex align-items-end fadeInLeft"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            {" "}
            <div class="section-title style-two mt-lg-0 mt-3">
              <h2 class="title">
                Explore Our <span>Latest Opportunities</span>
              </h2>
              <p class="col-11 p-0 m-0 mt-2">
                We are always looking for talented individuals to join our team.
                Check out our latest job openings and find the perfect role that
                matches your skills and passion. Apply today and be part of
                something great!.
              </p>

              <a
                href="#listofjobs"
                class="explore-btn text-white d-flex justify-content-center align-items-center"
              >
                Explore
              </a>
            </div>{" "}
          </div>
          <div class="col-lg-6 col-12  order-lg-5 order-1 ">
            <div class="company-img ">
              {" "}
              <div
                class=" fadeInRight"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <img
                  class="object-fit-contain p-4 w-100"
                  src={Image1}
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="job-posting container ">
          <div class="section-title style-two mt-lg-0 mt-3">
            <h2 class="title text-center" id="listofjobs">
              Jobs<span> List</span>
            </h2>
          </div>

          <div class="container">
            <iframe
              src="https://hrpundits.oorwin.com/careers/index.html"
              width="100%"
              height="1200"
              iframeborder="0"
              style={{ border: "none", margin: "0px", marginTop: "10px" }}
            ></iframe>
            {/* <!-- Search Bar Section --> */}
            {/* <div class="row d-flex mb-3">
              <div class="col-lg-4  col-12">
                <input
                  type="text"
                  class="form-control p-2"
                  placeholder="Job Title, Skills"
                  id="job-title"
                  value={searchTitle}
                  onChange={(e) => setSearchTitle(e.target.value)}
                />
              </div>
              <div class="col-lg-4  col-12 mt-lg-0 mt-2 ">
                <input
                  type="text"
                  class="form-control p-2"
                  placeholder="Location"
                  id="job-location"
                  value={searchLocation}
                  onChange={(e) => setSearchLocation(e.target.value)}
                />
              </div>
              <div class="col-lg-2 mt-lg-0 mt-2">
                <button
                  className="btn job-btns mb-4 w-full sm:w-auto text-base sm:text-lg bg-blue-500 text-white rounded-md shadow-md hover:bg-blue-600 transition duration-300"
                  onClick={()=>filterJobs()}
                >
                  Search Jobs
                </button>
              </div>
            </div>*/}

            {/* <!-- Job Listings Section --> 
            <div class="row p-0 m-0 ">
              <div
                class="col-lg-6 col-12 mt-lg-0 mt-3 order-lg-1 order-2"
                id="job-listings-container"
              >
                <div id="job-listings" class="list-group">
                  {filteredJobs.map((job, index) => (
                    <a
                      className="list-group-item list-group-item-action"
                      key={index}
                      onClick={() => showJobDetails(job)}
                    >
                      <div class="d-flex justify-content-between">
                        <h5 class="job-title">{job.jobTitle}</h5>
                        <p class="date">{formatDate(job.PostedDate)}</p>
                      </div>
                      <p>
                      {[job.jobLocation, job.jobType, job.experience]
                        .filter(Boolean) // Removes empty (null, undefined, or "") values
                        .join(" | ")}
                      </p>
                    </a>
                  ))}
                </div>

                
                <ul id="pagination" class="pagination">
            
                </ul>
              </div>

              {isJobselected && (
                <div class="col-lg-6 col-12 sticky-top order-lg-2 order-1">
                  <div class="card sticky-top" id="job-details">
                    <h4>{selectedJob?.jobTitle}</h4>
                    {selectedJob?.jobLocation ? (
                      <p>
                        <strong>Location:</strong> {selectedJob.jobLocation}
                      </p>
                    ) : null}
                    {selectedJob?.jobType ? (
                      <p>
                        <strong>Working:</strong> {selectedJob.jobType}
                      </p>
                    ) : null}
                    {selectedJob?.experience ? (
                      <p>
                        <strong>Experience:</strong> {selectedJob.experience}
                      </p>
                    ) : null}
                    {selectedJob?.Salary ? (
                      <p>
                        <strong>Salary:</strong> {selectedJob.Salary}
                      </p>
                    ) : null}
                    <p>
                      <strong>Job Description:</strong>
                    </p>
                    {selectedJob?.jobDescription?.length > 0 && (
                      <ul className="description">
                        <li>{selectedJob.jobDescription[0]}</li>
                        <div
                          id={`more-${selectedJob.id}`}
                          style={{ display: "none" }}
                        >
                          {selectedJob.jobDescription
                            .slice(1)
                            .map((point, index) => (
                              <li key={index}>{point}</li>
                            ))}
                        </div>
                      </ul>
                    )}
                    <a
                      id={`read-more-btn-${selectedJob.id}`}
                      onClick={() => toggleDescription(selectedJob.id)}
                    >
                      Read More
                    </a>

                    <button
                      id="apply-job"
                      className="btn job-btns mb-4 w-full sm:w-auto text-base sm:text-lg bg-blue-500 text-white rounded-md shadow-md hover:bg-blue-600 transition duration-300"
                      onClick={() => showModal()}
                    >
                      Apply Now
                    </button>
                  </div>
                </div>
              )}
            </div>*/}
          </div>
          {isModalOpen && (
            <ApplyModal
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              postedJobTitle={selectedJob?.jobTitle}
              jobId={selectedJob?._id}
            />
          )}
          <div
            class="modal fade"
            id="authModal"
            tabindex="-1"
            aria-labelledby="authModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="authModalLabel">
                    Authentication
                  </h5>
                  <button
                    type="button"
                    class="btn-close "
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i class="fa-solid fa-xmark fs-6"></i>
                  </button>
                </div>
                <div class="modal-body text-center">
                  <div class>
                    <label className="head-nav">First Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="First Name"
                    />
                  </div>
                  <div class>
                    <label className="head-nav">Last Name</label>
                    <input class="form-control" placeholder="Last Name" />
                  </div>
                  <div class>
                    <label className="head-nav">Email</label>
                    <input
                      type="email"
                      class="form-control"
                      placeholder="Email"
                    />
                  </div>
                  <div class>
                    <label className="head-nav">Phone Number</label>
                    <input
                      type="tel"
                      class="form-control"
                      placeholder="Phone Number"
                    />
                  </div>
                  <div>
                    <label className="head-nav">Country</label>
                    <select id="country" class="form-control" name="country">
                      <option value>Select Country</option>
                      <option value="Canada">Canada</option>
                      <option value="United States">United States</option>
                      <option value="United Kingdom">United Kingdom</option>
                      <option value="Australia">Australia</option>
                      <option value="India">India</option>
                      <option value="Others">Others</option>
                    </select>
                  </div>
                  <div class>
                    <label className="head-nav">State</label>
                    <select id="state" class="form-control" name="state">
                      <option value>Select State</option>
                      <option value="Ontario">Ontario</option>
                      <option value="California">California</option>
                      <option value="New York">New York</option>
                      <option value="Queensland">Queensland</option>
                      <option value="Maharashtra">Maharashtra</option>
                      <option value="Others">Others</option>
                    </select>
                  </div>
                  <div class>
                    <label className="head-nav">City</label>
                    <select id="city" name="city" class="form-control">
                      <option value>Select City</option>
                      <option value="Toronto">Toronto</option>
                      <option value="Los Angeles">Los Angeles</option>
                      <option value="New York City">New York City</option>
                      <option value="Brisbane">Brisbane</option>
                      <option value="Mumbai">Mumbai</option>
                      <option value="Others">Others</option>
                    </select>
                  </div>

                  <div class>
                    <label className="head-nav">Job Title</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Job Title"
                    />
                  </div>
                  <div>
                    <label className="head-nav">Experience</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Experience"
                    />
                  </div>
                  <br />
                  <button type="submit" class="btn-blue subcribe-submit">
                    submit
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            class="modal fade"
            id="applyModal"
            tabindex="-1"
            aria-labelledby="applyModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="applyModalLabel">
                    Apply for Job
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <form id="applyForm">
                    <div class="mb-3">
                      <label for="applicantName" class="form-label">
                        Name
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="applicantName"
                        required
                      />
                    </div>
                    <div class="mb-3">
                      <label for="applicantEmail" class="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        class="form-control"
                        id="applicantEmail"
                        required
                      />
                    </div>
                    <div class="mb-3">
                      <label for="jobTitle" class="form-label">
                        Job Title
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="jobTitle"
                        readonly
                      />
                    </div>
                    <div class="mb-3">
                      <label for="applicantCV" class="form-label">
                        Upload CV
                      </label>
                      <input
                        type="file"
                        class="form-control"
                        id="applicantCV"
                        accept=".pdf, .doc, .docx"
                        required
                      />
                    </div>
                    <button type="submit" class="btn job-btns">
                      Submit Application
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterTwo />

      <div class="back-to-top">
        <span class="back-top">
          <i class="fa fa-angle-up"></i>
        </span>
      </div>
    </div>
  );
};

export default JobPosting;

{
  /* <footer class="footer-area footer-area-2 mg-top-105">
          <div class="container">
            <div class="footer-widget-area mg-top-120">
              <div class="row">
                <div class="col-lg-3 col-md-6">
                  <div class="footer-widget widget">
                    <div class="about_us_widget">
                      <a href="#" class="footer-logo">
                        <img src="images/logo.png" alt="footer logo" />
                      </a>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy.
                      </p>
                      <ul class="social-icon">
                        <li>
                          <a class="facebook" href="#" target="_blank">
                            <i class="fa fa-facebook  "></i>
                          </a>
                        </li>
                        <li>
                          <a class="twitter" href="#" target="_blank">
                            <i class="fa fa-twitter  "></i>
                          </a>
                        </li>
                        <li>
                          <a class="linkedin" href="#" target="_blank">
                            <i class="fa fa-linkedin"></i>
                          </a>
                        </li>
                        <li>
                          <a class="pinterest" href="#" target="_blank">
                            <i class="fa fa-instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-lg-2 col-md-6">
                  <div class="footer-widget widget ">
                    <h4 class="widget-title">Contact</h4>
                    <div class="contact_info_list">
                      <p class="contact-content">
                        1827 Walden office Sq Suite # 260 Schaumburg, IL- 60173.
                      </p>
                      <p>
                        <span>Contact:</span> 847 598 1122
                      </p>
                      <p>
                        <span>E-mail:</span> info@hrpundits.com
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-2 col-md-6">
                  <div class="footer-widget widget widget_nav_menu">
                    <h4 class="widget-title">Quick Link</h4>
                    <ul>
                      <li>
                        <a href="#">Talent Management</a>
                      </li>
                      <li>
                        <a href="#">Application Development</a>
                      </li>
                      <li>
                        <a href="#">ERP</a>
                      </li>
                      <li>
                        <a href="#">Training</a>
                      </li>
                      <li>
                        <a href="#">Corporate Training</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-2 col-md-6">
                  <div class="footer-widget widget widget_nav_menu">
                    <h4 class="widget-title">Useful Links</h4>

                    <ul>
                      <li>
                        <a
                          href="http://www12.sap.com/index.epx"
                          target="_blank"
                        >
                          SAP.com
                        </a>
                      </li>
                      <li>
                        <a href="http://help.sap.com/" target="_blank">
                          help.SAP.com
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://websmp205.sap-ag.de/hrusa"
                          target="_blank"
                        >
                          SAP Marketplace
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://www.sap.com/usa/services/education/certification/index.epx"
                          target="_blank"
                        >
                          SAP HCM Certification
                        </a>
                      </li>
                      <li>
                        <a href="http://www.paycheckcity.com/" target="_blank">
                          Payroll Calculation
                        </a>
                      </li>
                      <li>
                        <a href="http://www.asug.com/" target="_blank">
                          ASUG
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://66.147.244.204/~hrpundit/index.php?option=com_content&amp;view=article&amp;id=64"
                          target="_blank"
                        >
                          Common Job Sites
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="footer-widget widget">
                    <h4 class="widget-title">Latest News</h4>
                    <div class="about_recent_post">
                      <div class="media">
                        <img src="assets/img/blog/widget1.png" alt="post" />
                        <div class="media-body">
                          <h6>
                            <a href="#">New Feature Added</a>
                          </h6>
                          <span>21 August 2019</span>
                        </div>
                      </div>
                      <div class="media">
                        <img src="assets/img/blog/widget2.png" alt="post" />
                        <div class="media-body">
                          <h6>
                            <a href="#">Business Development</a>
                          </h6>
                          <span>21 August 2019</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="copyright-inner">
            <div class="copyright-text">
              Copyright © 2023. HR Pundits. All rights reserved.
            </div>
          </div>
        </footer> */
}
