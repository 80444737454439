import React, { useState, useEffect } from "react";
// import "./SoftSkills.css";
import "./SoftSkills.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import FooterTwo from "../Footer2/FooterTwo";
import Image1 from "../../assets/img/web_asstes/soft-skills.png";
import Image2 from "../../assets/img/web_asstes/what-we-can-do.png";
import Image3 from "../../assets/img/web_asstes/team-vector.png";
import Image4 from "../../assets/img/web_asstes/team-vector.png";
import Image5 from "../../assets/img/web_asstes/team-vector-women.png";
import Image6 from "../../assets/img/web_asstes/team-vector-women.png";
import { Swiper, SwiperSlide } from "swiper/react";

import axios from "axios";
import { Toaster, toast } from "react-hot-toast";
import { Autoplay, Pagination } from "swiper/modules";

const SoftSkills = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [profession, setProfession] = useState("");
  const [primaryPurposeLearning, setPrimaryPurposeLearning] = useState("");
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState({
    name: "",
    email: "",
    profession: "",
    primaryPurposeLearning: "",
  });
  const validateForm = () => {
    const errors = {};

    if (name.trim() === "") {
      errors.name = "Name is required";
    }
    if (email.trim() === "") {
      errors.email = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      errors.email = "Invalid email format";
    }
    if (!profession.trim()) {
      errors.profession = "Profession is required";
    }
    if (primaryPurposeLearning.trim() === "") {
      errors.primaryPurposeLearning = "Primary Purpose Learning is required";
    }

    setError(errors);

    return Object.keys(errors).length === 0; // Returns true if no errors
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    validateForm();
    try {
      if (validateForm()) {
        const payload = {
          name: name,
          email: email,
          profession: profession,
          primaryPurpose: primaryPurposeLearning,
        };
        const { data } = await axios.post(
          `${process.env.REACT_APP_SERVER}/api/email/softSkills`,
          payload
        );
        console.log(data);
        toast.success("Form Submitted");
        setLoader(false);
      } else {
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);

      if (error?.response?.data) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong");
      }
    } finally {
      setName("");
      setEmail("");
      setProfession("");
      setPrimaryPurposeLearning("");
    }
  };
  // console.log(error);
  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />

      {/* !-- preloader area start --> */}
      {/* <div className="preloader" id="preloader">
        <div className="preloader-inner">
          <div className="spinner">
            <div className="dot1"></div>
            <div className="dot2"></div>
          </div>
        </div>
      </div> */}
      {/* <!-- preloader area end -->

<!-- search Popup --> */}
      <div className="body-overlay" id="body-overlay"></div>
      <div className="search-popup" id="search-popup">
        <form
          action="http://zwin.io/html/riyaqas/index.html"
          className="search-form"
        >
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Search....."
            />
          </div>
          <button type="submit" className="submit-btn">
            <i className="fa fa-search"></i>
          </button>
        </form>
      </div>
      {/* <!-- //. search Popup --> */}

      {/* <!-- navbar area start --> */}
      <Navbar />
      {/* <!-- navbar area end --> */}

      <div className="container Soft-skills-content  py-3">
        <div className="section-title style-two mt-lg-0 mt-3">
          <h2 className="title text-center ">
            Soft <span>Skills</span>
          </h2>
        </div>
        <div className=" d-flex justify-content-around flex-wrap ">
          <div
            className="ebooks col-lg-6 col-12 order-lg-1 order-5 d-flex  align-items-end  fadeInLeft"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            {" "}
            <div className="section-title style-two mt-lg-0 mt-3">
              <h2 className="title">
                What Are <span>Soft Skills?</span>
              </h2>
              <p className="col-11 p-0 m-0 mt-2">
                Soft skills, also known as "people skills" or "interpersonal
                skills," are personal attributes and abilities that enhance an
                individual's interactions, job performance, and career
                prospects. Unlike hard skills, which are specific to a
                particular job or industry, soft skills are universally
                applicable and highly valued across all professions. They
                include the ability to collaborate effectively, manage time, and
                communicate clearly. Although difficult to measure, soft skills
                are essential for success in any industry, as they enable
                individuals to navigate complex interpersonal dynamics and build
                strong relationships with colleagues, clients, and customers
                from diverse backgrounds.
              </p>

              {/* <!--<a href="#six-Soft-Skills" className="explore-btn text-white d-flex justify-content-center align-items-center">Explore <i className="fa fa-car"></i></a>--> */}
            </div>{" "}
          </div>
          <div className="col-lg-6 col-12  order-lg-5 order-1 ">
            <div className="company-img ">
              {" "}
              <div
                className=" wow animated fadeInRight"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <img
                  className="object-fit-contain p-4 w-100"
                  src={Image1}
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="six-skills container mt-4 " id="six-Soft-Skills">
          <div className="row m-0 p-0 skills gap-3 d-flex justify-content-around  align-items-center flex-wrap ">
            <div className="skill p-4 mt-3">
              <h5 className="box-heading">
                <span>Communication</span>
              </h5>
              <p>
                {" "}
                Effective communication is key to building strong relationships
                with colleagues, clients, and stakeholders. It involves
                conveying messages clearly and understanding others' needs.
                Employers value people who can communicate well, whether through
                active listening, verbal, nonverbal, or written communication,
                and presentation skills.
              </p>
              {/* <!-- <a href="#" className="explore-btn text-white d-flex justify-content-center align-items-center">View More</a> --> */}
            </div>
            <div className="skill p-4 mt-3">
              <h5 className="box-heading">
                <span>Teamwork</span>
              </h5>
              <p>
                {" "}
                Teamwork is the ability to work with others toward a shared
                goal. It requires communication, collaboration, and commitment.
                Team players excel in conflict resolution, mediation,
                accountability, and collaboration, contributing to creativity,
                innovation, and a positive work environment.
              </p>
              {/* <!-- <a href="#" className="explore-btn text-white d-flex justify-content-center align-items-center">View More</a> --> */}
            </div>
            <div className="skill p-4 mt-3">
              <h5 className="box-heading">
                <span>Creativity</span>
              </h5>
              <p>
                Creativity is thinking outside the box to generate new ideas and
                solutions. It involves brainstorming, imagination, curiosity,
                and experimentation. Creative individuals drive innovation and
                give organizations a competitive edge.{" "}
              </p>
              {/* <!-- <a href="#" className="explore-btn text-white d-flex justify-content-center align-items-center">View More</a> --> */}
            </div>
            <div className="skill p-4 mt-3">
              <h5 className="box-heading">
                <span>Time Management</span>
              </h5>
              <p>
                Time management is about setting priorities, organizing tasks,
                and managing time effectively. Skills include planning,
                goal-setting, delegation, and time blocking. Good time
                management reduces stress, increases reliability, and enhances
                efficiency{" "}
              </p>
              {/* <!-- <a href="#" className="explore-btn text-white d-flex justify-content-center align-items-center">View More</a> --> */}
            </div>
            <div className="skill p-4 mt-3">
              <h5 className="box-heading">
                <span> Adaptability</span>
              </h5>
              <p>
                Adaptability allows individuals to adjust to changing
                circumstances and learn new skills. It involves flexibility,
                resilience, a growth mindset, and analysis. Adaptable employees
                help organizations navigate challenges and remain competitive.
              </p>
              {/* <!-- <a href="#" className="explore-btn text-white d-flex justify-content-center align-items-center">View More</a> --> */}
            </div>
            <div className="skill p-4 mt-3">
              <h5 className="box-heading">
                <span>Work ethics</span>
              </h5>
              <p>
                Work ethic is a commitment to one’s job, demonstrated by
                punctuality, reliability, professionalism, and discipline. A
                strong work ethic builds credibility and dependability in the
                workplace.{" "}
              </p>
              {/* <!-- <a href="#" className="explore-btn text-white d-flex justify-content-center align-items-center">View More</a> --> */}
            </div>
          </div>
        </div>

        <div className="container mt-5 py-5 d-flex flex-column justify-content-center align-items-center">
          {/* <!-- Main Content Section --> */}
          <div className="d-flex mt-5 py-3 justify-content-between align-items-start gap-5 flex-wrap">
            {/* <!-- Training Information Section --> */}
            <div
              className="ebooks  col-12 order-lg-1 order-5  wow animated fadeInLeft"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              <div className="section-title style-two mt-lg-0 mt-3 text-center">
                <h6 className="title">
                  <span>Interested</span> in Learning More?
                </h6>
                <p>
                  We provide training in a wide range of soft skills, essential
                  for personal and professional growth. Our programs cover key
                  areas such as communication, teamwork, leadership,
                  problem-solving, and emotional intelligence. Each session
                  includes clear definitions and practical examples for easy
                  understanding and real-life application. Whether you're
                  looking to enhance workplace dynamics or personal development,
                  our courses are designed to meet your needs. With interactive
                  and engaging content, participants gain valuable insights and
                  actionable skills.
                </p>
                <p>
                  Ready to elevate your soft skills? Discover more with our
                  tailored training programs!
                </p>
              </div>
            </div>

            <div
              className="col-12 order-lg-5 order-1 wow animated fadeInRight"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              <div className="section-title style-two mt-lg-0 mt-3">
                <h2 className="title text-center">
                  Enquiry <span>Form</span>
                </h2>
                <div className="d-flex align-items-center justify-content-center mt-3">
                  <div className="inquiry-form col-lg-8 col-12">
                    <form id="inquiryForm" onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label for="name">Name:</label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          className="form-control"
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                            setError((prev) => ({ ...prev, name: "" }));
                          }}
                        />
                        <span style={{ color: "red" }}>{error.name}</span>
                      </div>
                      <div className="form-group">
                        <label for="email">Email:</label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="form-control"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                            setError((prev) => ({ ...prev, email: "" }));
                          }}
                        />
                        <span style={{ color: "red" }}>{error.email}</span>
                      </div>
                      <div className="form-group">
                        <label for="profession">Profession:</label>
                        <select
                          id="profession"
                          name="profession"
                          className="form-control"
                          value={profession}
                          onChange={(e) => {
                            setProfession(e.target.value);
                            setError((prev) => ({ ...prev, profession: "" }));
                          }}
                        >
                          <option value="">Select Profession</option>
                          <option value="Student">Student</option>
                          <option value="College Head">College Head</option>
                          <option value="Consultant">Consultant</option>
                          <option value="Employee">Employee</option>
                          <option value="Company Head">Company Head</option>
                          <option value="Others">Others</option>
                        </select>
                        <span style={{ color: "red" }}>{error.profession}</span>
                      </div>
                      <div className="form-group">
                        <label for="purpose">
                          Primary Purpose of Learning Soft Skills:
                        </label>
                        <textarea
                          id="purpose"
                          name="purpose"
                          rows="4"
                          className="form-control"
                          value={primaryPurposeLearning}
                          onChange={(e) => {
                            setPrimaryPurposeLearning(e.target.value);
                            setError((prev) => ({
                              ...prev,
                              primaryPurposeLearning: "",
                            }));
                          }}
                        ></textarea>
                        <span style={{ color: "red" }}>
                          {error.primaryPurposeLearning}
                        </span>
                      </div>
                      <button
                        id="submitButton"
                        type="submit"
                        className="btn btn-primary mt-3"
                        style={{ padding: "0" }}
                      >
                        {loader ? "Submitting" : "Submit"}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container what-we-can-do mt-5">
          <div className=" d-flex justify-content-around flex-wrap ">
            <div
              className="ebooks col-lg-6 col-12 order-lg-1 order-5 d-flex  align-items-center wow animated fadeInLeft"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              {" "}
              <div className="section-title style-two mt-lg-0 mt-3">
                <h2 className="title">
                  What We<span> Can Do</span>
                </h2>
                <p className="col-11 p-0 m-0 mt-2">
                  We offer comprehensive training programs that encompass a wide
                  range of soft skills, empowering you to develop crucial
                  interpersonal and professional abilities. Our programs are
                  tailored to equip you with the tools needed to thrive in any
                  professional setting.
                </p>

                <a
                  href="#six-Soft-Skills"
                  className="explore-btn text-white d-flex justify-content-center align-items-center"
                >
                  Explore <i className="fa fa-car"></i>
                </a>
              </div>{" "}
            </div>
            <div className="col-lg-6 col-12  order-lg-5 order-1 ">
              <div className="company-img ">
                {" "}
                <div
                  className=" wow animated fadeInRight"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <img
                    className="object-fit-contain p-4 w-100"
                    src={Image2}
                    alt="img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container our-team mt-5">
          <div className="section-title style-two mt-lg-0 mt-3">
            <h2 className="title text-center">
              Our<span> Team</span>
            </h2>
          </div>

          {/* <!-- Clients Swiper Container Added Here --> */}
          <div className="Clients-swiper-container">
            <Swiper
              // loop={true}
              // autoplay={{
              //   delay: 2000,
              //   disableOnInteraction: false,
              // }}
              pagination={{
                clickable: true,
              }}
              modules={[Autoplay, Pagination]}
              spaceBetween={20}
              slidesPerView={4}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1440: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
                2560: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
              }}
            >
              <SwiperSlide>
                <div className="swiper-slide p-2 Clients-swiper-slide d-flex justify-content-start align-items-start">
                  <div className="cliend-name-img d-flex align-items-center">
                    <div className="clind-img col-6">
                      <img src={Image3} alt="Client Image 1" />
                    </div>
                    <div className="col-6">
                      <h5 className="p-0 m-0">KK.Reddy</h5>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide p-2 Clients-swiper-slide d-flex justify-content-start align-items-start">
                  <div className="cliend-name-img d-flex align-items-center">
                    <div className="clind-img col-6">
                      <img src={Image4} alt="Client Image 2" />
                    </div>
                    <div className="col-6">
                      <h5 className="p-0 m-0">John Bean</h5>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide p-2 Clients-swiper-slide d-flex justify-content-start align-items-start">
                  <div className="cliend-name-img d-flex align-items-center">
                    <div className="clind-img col-6">
                      <img src={Image5} alt="Client Image 3" />
                    </div>
                    <div className="col-6">
                      <h5 className="p-0 m-0">Leslie Chirchirillo</h5>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide p-2 Clients-swiper-slide d-flex justify-content-start align-items-start">
                  <div className="cliend-name-img d-flex align-items-center">
                    <div className="clind-img col-6">
                      <img src={Image6} alt="Client Image 4" />
                    </div>
                    <div className="col-6">
                      <h5 className="p-0 m-0">Divya</h5>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>

        {/* <!-- footer area start --> */}
        {/* <footer className="footer-area footer-area-2 mg-top-105">
          <div className="container">
            <div className="footer-widget-area mg-top-120">
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div className="footer-widget widget">
                    <div className="about_us_widget">
                      <a href="#" className="footer-logo">
                        <img src="images/logo.png" alt="footer logo" />
                      </a>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy.
                      </p>
                      <ul className="social-icon">
                        <li>
                          <a className="facebook" href="#" target="_blank">
                            <i className="fa fa-facebook  "></i>
                          </a>
                        </li>
                        <li>
                          <a className="twitter" href="#" target="_blank">
                            <i className="fa fa-twitter  "></i>
                          </a>
                        </li>
                        <li>
                          <a className="linkedin" href="#" target="_blank">
                            <i className="fa fa-linkedin"></i>
                          </a>
                        </li>
                        <li>
                          <a className="pinterest" href="#" target="_blank">
                            <i className="fa fa-instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6">
                  <div className="footer-widget widget ">
                    <h4 className="widget-title">Contact</h4>
                    <div className="contact_info_list">
                      <p className="contact-content">
                        1827 Walden office Sq Suite # 260 Schaumburg, IL- 60173.
                      </p>
                      <p>
                        <span>Contact:</span> 847 598 1122
                      </p>
                      <p>
                        <span>E-mail:</span> info@hrpundits.com
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6">
                  <div className="footer-widget widget widget_nav_menu">
                    <h4 className="widget-title">Quick Link</h4>
                    <ul>
                      <li>
                        <a href="#">Talent Management</a>
                      </li>
                      <li>
                        <a href="#">Application Development</a>
                      </li>
                      <li>
                        <a href="#">ERP</a>
                      </li>
                      <li>
                        <a href="#">Training</a>
                      </li>
                      <li>
                        <a href="#">Corporate Training</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6">
                  <div className="footer-widget widget widget_nav_menu">
                    <h4 className="widget-title">Useful Links</h4>

                    <ul>
                      <li>
                        <a
                          href="http://www12.sap.com/index.epx"
                          target="_blank"
                        >
                          SAP.com
                        </a>
                      </li>
                      <li>
                        <a href="http://help.sap.com/" target="_blank">
                          help.SAP.com
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://websmp205.sap-ag.de/hrusa"
                          target="_blank"
                        >
                          SAP Marketplace
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://www.sap.com/usa/services/education/certification/index.epx"
                          target="_blank"
                        >
                          SAP HCM Certification
                        </a>
                      </li>
                      <li>
                        <a href="http://www.paycheckcity.com/" target="_blank">
                          Payroll Calculation
                        </a>
                      </li>
                      <li>
                        <a href="http://www.asug.com/" target="_blank">
                          ASUG
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://66.147.244.204/~hrpundit/index.php?option=com_content&amp;view=article&amp;id=64"
                          target="_blank"
                        >
                          Common Job Sites
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="footer-widget widget">
                    <h4 className="widget-title">Latest News</h4>
                    <div className="about_recent_post">
                      <div className="media">
                        <img src="assets/img/blog/widget1.png" alt="post" />
                        <div className="media-body">
                          <h6>
                            <a href="#">New Feature Added</a>
                          </h6>
                          <span>21 August 2019</span>
                        </div>
                      </div>
                      <div className="media">
                        <img src="assets/img/blog/widget2.png" alt="post" />
                        <div className="media-body">
                          <h6>
                            <a href="#">Business Development</a>
                          </h6>
                          <span>21 August 2019</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright-inner">
            <div className="copyright-text">
              Copyright © 2023. HR Pundits. All rights reserved.
            </div>
          </div>
        </footer> */}
        <FooterTwo />
        {/* <!-- footer area end --> */}

        {/* <!-- back to top area start --> */}
        <div className="back-to-top">
          <span className="back-top">
            <i className="fa fa-angle-up"></i>
          </span>
        </div>
      </div>
    </div>
  );
};

export default SoftSkills;
