import React from "react";
import logo from "../../images/logo.png";
import image1 from "../../assets/img/blog/widget1.png";
import image2 from "../../assets/img/blog/widget2.png";
// import image3 from "../../assets/img/blog/widget1.png";
// import image4 from "../../assets/img/blog/widget1.png";

const Footer = () => {
  return (
    <div>
      <footer className="footer-area footer-area-2 mg-top-105">
        <div className="container">
          <div className="footer-widget-area mg-top-120">
            <div className="row d-flex justify-content-around ">
              <div className="col-lg-3 col-md-6">
                <div className="footer-widget widget">
                  <div className="about_us_widget">
                    <a href="#" className="footer-logo">
                      <img src={logo} alt="footer logo" />
                    </a>
                    <p>
                      Established in 2005 as Minority and Small Business
                      Enterprise in the State of Illinois, HR Pundits today is
                      one of the leading solution providers of SAP ERP HCM
                      Technology.
                    </p>
                    <ul className="social-icon">
                      <li>
                        <a href="https://www.facebook.com/profile.php?id=100063794947852#" className="facebook"  target="_blank">
                          <i className="fa fa-facebook  "></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://x.com/i/flow/login?redirect_after_login=%2FHRPu_SAP_HR_HCM" className="twitter"  target="_blank">
                          <i className="fa fa-twitter  "></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/company/hr-pundits-inc---sap-hr-hcm-training-and-projects/?trk=fc_badge" className="linkedin"  target="_blank">
                          <i className="fa fa-linkedin"></i>
                        </a>
                      </li>
                     {/* <li>
                        <a className="pinterest" href="#" target="_blank">
                          <i className="fa fa-instagram"></i>
                        </a>
                      </li>*/}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6">
                <div className="footer-widget widget ">
                  <h4 className="widget-title">Contact</h4>
                  <div className="contact_info_list">
                    <p className="contact-content">
                      1827 Walden office Sq Suite # 260 Schaumburg, IL- 60173.
                    </p>
                    <p>
                      <span>Contact:</span> 847 598 1122
                    </p>
                    <p>
                      <span>E-mail:</span> info@hrpundits.com
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6">
                <div className="footer-widget widget widget_nav_menu">
                  <h4 className="widget-title">Quick Link</h4>
                  <ul>
                    <li>
                      <a href="#">Talent Management</a>
                    </li>
                    <li>
                      <a href="#">Application Development</a>
                    </li>
                    <li>
                      <a href="#">ERP</a>
                    </li>
                    <li>
                      <a href="#">Training</a>
                    </li>
                    <li>
                      <a href="#">Corporate Training</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="footer-widget widget">
                  <h4 className="widget-title">Latest News</h4>
                  <div className="about_recent_post">
                    <div className="media">
                      <img src={image1} alt="post" />
                      <div className="media-body">
                        <h6>
                          <a href="#">New Feature Added</a>
                        </h6>
                        <span>21 August 2019</span>
                      </div>
                    </div>
                    <div className="media">
                      <img src={image2} alt="post" />
                      <div className="media-body">
                        <h6>
                          <a href="#">Business Development</a>
                        </h6>
                        <span>21 August 2019</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-inner">
          <div className="copyright-text">
            Copyright © 2023. HR Pundits. All rights reserved.
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
