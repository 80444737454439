import React from "react";
import "./SapHCM.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Image1 from "../../assets/img/web_asstes/kk reddy.png";
import Image2 from "../../assets/img/web_asstes/icon-1.png";
import Image3 from "../../assets/img/web_asstes/icon-2.png";
import Image4 from "../../assets/img/web_asstes/icon-3.png";
import Image5 from "../../assets/img/web_asstes/Our-Unique-Approach.png";
import FooterTwo from "../Footer2/FooterTwo";

const SapHCM = () => {
  return (
    <div>
      {/* <!-- preloader area start --> */}
      {/* <div class="preloader" id="preloader">
        <div class="preloader-inner">
          <div class="spinner">
            <div class="dot1"></div>
            <div class="dot2"></div>
          </div>
        </div>
      </div> */}
      {/* <!-- preloader area end -->
  
  <!-- search Popup --> */}
      <div class="body-overlay" id="body-overlay"></div>
      <div class="search-popup" id="search-popup">
        <form
          action="http://zwin.io/html/riyaqas/index.html"
          class="search-form"
        >
          <div class="form-group">
            <input type="text" class="form-control" placeholder="Search....." />
          </div>
          <button type="submit" class="submit-btn">
            <i class="fa fa-search"></i>
          </button>
        </form>
      </div>
      {/* <!-- //. search Popup -->
  
  <!-- navbar area start --> */}
      <Navbar />
      {/* <!-- navbar area end --> */}

      <div class="container Soft-skills-content  py-4 ">
        <div class="section-title style-two mt-lg-0 mt-2 text-center">
          <h2 class="title  ">
            <span> SAP HCM</span> & <span> SuccessFactors </span>
          </h2>{" "}
          <p>Expert HR Solutions Delivered by HR Pundits</p>
        </div>
        <div class="row d-flex justify-content-center align-items-center flex-wrap ">
          <div
            class="ebooks mt-4 col-lg-12 col-12 order-lg-1 order-5 d-flex  align-items-center wow animated fadeInLeft"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            {" "}
            <div class="section-title style-two mt-lg-0 mt-3">
              <h2 class="title text-center">
                About <span>HR Pundits</span>
              </h2>
              <p>
                HR Pundits is formed by a highly experienced group of HR
                professionals specializing in delivering SAP HCM and
                SuccessFactors solutions. The company operates under the
                leadership of Krishna K. Reddy, CEO. Mr. Reddy has over 22 years
                of extensive HR domain experience, including 11 years as an SAP
                consultant, where he has architected and implemented SAP HCM and
                SuccessFactors solutions for Fortune 100 clients.
              </p>
              {/* <!--<a href="#Recruitment-Services" class="explore-btn text-white d-flex justify-content-center align-items-center mt-4">Let's Connect </a>--> */}
            </div>{" "}
          </div>
          {/*    <div class="col-lg-6 col-12 text-center  order-lg-5 order-1 ">
          <div class="company-img ">
            {" "}
            <div
              class=" wow animated fadeInRight"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              <img
                class="object-fit-contain w-100"
                src={Image1}
                alt="img"
              />
              <p class="m-0 p-0">
                <strong>Mr. Krishna K. Reddy, CEO</strong>
                <br /> Over 22 years of HR domain experience
              </p>
            </div>
          </div>
        </div>*/}
        </div>
      </div>

      {/* <!-- Expertise Section --> */}
      <section class="content-section mt-5 ">
        <div class="container">
          <div class="container">
            {" "}
            <div class="section-title style-two mt-lg-0 mt-5">
              <h2 class="title text-center ">
                Our<span> Expertise</span>{" "}
              </h2>

              <p class="text-center">
                Over the years, we have successfully completed SAP HCM and
                SuccessFactors engagements for numerous global, midsize, and
                small customers.
              </p>
            </div>
          </div>

          <h2 class="section-title text-center"> </h2>

          <div class="row mt-4 ">
            <div class="col-md-4 icon-box  d-flex text-center flex-column justify-content-center align-items-center">
              <img class="icon-SAP-HCM" src={Image2} alt="Icon" />
              <p>
                End-to-end solutions: From developing business cases to
                implementation and support.
              </p>
            </div>
            <div class="col-md-4 icon-box d-flex text-center flex-column justify-content-center align-items-center ">
              <img class="icon-SAP-HCM" src={Image3} alt="Icon" />
              <p>
                Project managers assemble the right project teams to fit
                specific customer needs.
              </p>
            </div>
            <div class="col-md-4 icon-box d-flex text-center flex-column justify-content-center align-items-center">
              <img class="icon-SAP-HCM" src={Image4} alt="Icon" />
              <p>
                Consultants balance over-processing and under-processing to
                deliver cost-effective solutions.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Approach Section -->
<!-- <section class="content-section mt-5">
    <div class="container">
        <div class="d-flex gap-5  justify-content-around align-items-center bg-danger">
            <div class="col-lg-6 col-12">
                <h2 class="section-title">Our Unique Approach</h2>
                <p>Implementing any SAP HCM and SuccessFactors solution requires a keen understanding of how to
                    balance technical expertise with functional HR expertise, aligning the technology solution with
                    the organization’s HR strategy and business goals.</p>
            </div>
            <div class="col-lg-6 col-12">
                <img src="https://via.placeholder.com/400x300" class="img-fluid" alt="Approach Diagram"/>
            </div>
        </div>
    </div>
</section> --> */}

      <div class="container Soft-skills-content mt-5 py-4">
        <div class=" d-flex justify-content-around flex-wrap ">
          <div
            class="ebooks mt-4 col-lg-6 col-12 order-lg-1 order-5 d-flex   align-items-center wow animated fadeInLeft"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            {" "}
            <div class="section-title style-two mt-lg-0 mt-3">
              <div class="section-title style-two mt-lg-0 ">
                <h2 class="title text-start ">
                  Our <span>Unique Approach</span>{" "}
                </h2>
              </div>
              <p>
                Implementing an SAP HCM and SuccessFactors solution requires a
                strategic balance between technical and HR expertise. It
                involves ensuring that the technology functions efficiently
                while aligning with the organization's HR strategy and overall
                business goals. A successful implementation not only addresses
                the technical aspects but also integrates seamlessly with the
                company’s human resources processes.
              </p>
              <p>
                {" "}
                This comprehensive approach helps organizations leverage SAP HCM
                and SuccessFactors to optimize their HR operations, improve
                efficiency, and drive business success
              </p>
            </div>{" "}
          </div>
          <div class="col-lg-6 col-12 text-center  order-lg-5 order-1 ">
            <div class="company-img ">
              {" "}
              <div
                class=" wow animated fadeInRight"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <img src={Image5} alt="" />
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Call to Action Section -->
<!-- <section class="text-center py-5 bg-light ">
    <div class="container">
        <h2>Partner with HR Pundits for Expert HR Solutions</h2>
        <p>This expertise and insight are what we bring to you as your solution partner.</p>
        <button class="cta-button">Learn More</button>
    </div>
</section> --> */}

        {/* <!-- footer area start --> */}
        {/* <footer class="footer-area footer-area-2 mg-top-105">
          <div class="container">
            <div class="footer-widget-area mg-top-120">
              <div class="row">
                <div class="col-lg-3 col-md-6">
                  <div class="footer-widget widget">
                    <div class="about_us_widget">
                      <a href="#" class="footer-logo">
                        <img src="images/logo.png" alt="footer logo" />
                      </a>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy.
                      </p>
                      <ul class="social-icon">
                        <li>
                          <a class="facebook" href="#" target="_blank">
                            <i class="fa fa-facebook  "></i>
                          </a>
                        </li>
                        <li>
                          <a class="twitter" href="#" target="_blank">
                            <i class="fa fa-twitter  "></i>
                          </a>
                        </li>
                        <li>
                          <a class="linkedin" href="#" target="_blank">
                            <i class="fa fa-linkedin"></i>
                          </a>
                        </li>
                        <li>
                          <a class="pinterest" href="#" target="_blank">
                            <i class="fa fa-instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-lg-2 col-md-6">
                  <div class="footer-widget widget ">
                    <h4 class="widget-title">Contact</h4>
                    <div class="contact_info_list">
                      <p class="contact-content">
                        1827 Walden office Sq Suite # 260 Schaumburg, IL- 60173.
                      </p>
                      <p>
                        <span>Contact:</span> 847 598 1122
                      </p>
                      <p>
                        <span>E-mail:</span> info@hrpundits.com
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-2 col-md-6">
                  <div class="footer-widget widget widget_nav_menu">
                    <h4 class="widget-title">Quick Link</h4>
                    <ul>
                      <li>
                        <a href="#">Talent Management</a>
                      </li>
                      <li>
                        <a href="#">Application Development</a>
                      </li>
                      <li>
                        <a href="#">ERP</a>
                      </li>
                      <li>
                        <a href="#">Training</a>
                      </li>
                      <li>
                        <a href="#">Corporate Training</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-2 col-md-6">
                  <div class="footer-widget widget widget_nav_menu">
                    <h4 class="widget-title">Useful Links</h4>

                    <ul>
                      <li>
                        <a
                          href="http://www12.sap.com/index.epx"
                          target="_blank"
                        >
                          SAP.com
                        </a>
                      </li>
                      <li>
                        <a href="http://help.sap.com/" target="_blank">
                          help.SAP.com
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://websmp205.sap-ag.de/hrusa"
                          target="_blank"
                        >
                          SAP Marketplace
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://www.sap.com/usa/services/education/certification/index.epx"
                          target="_blank"
                        >
                          SAP HCM Certification
                        </a>
                      </li>

                      <li>
                        <a href="http://www.paycheckcity.com/" target="_blank">
                          Payroll Calculation
                        </a>
                      </li>
                      <li>
                        <a href="http://www.asug.com/" target="_blank">
                          ASUG
                        </a>
                      </li>
                      <li>
                        <a
                          href="http://66.147.244.204/~hrpundit/index.php?option=com_content&amp;view=article&amp;id=64"
                          target="_blank"
                        >
                          Common Job Sites
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="footer-widget widget">
                    <h4 class="widget-title">Latest News</h4>
                    <div class="about_recent_post">
                      <div class="media">
                        <img src="assets/img/blog/widget1.png" alt="post" />
                        <div class="media-body">
                          <h6>
                            <a href="#">New Feature Added</a>
                          </h6>
                          <span>21 August 2019</span>
                        </div>
                      </div>
                      <div class="media">
                        <img src="assets/img/blog/widget2.png" alt="post" />
                        <div class="media-body">
                          <h6>
                            <a href="#">Business Development</a>
                          </h6>
                          <span>21 August 2019</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="copyright-inner">
            <div class="copyright-text">
              Copyright © 2023. HR Pundits. All rights reserved.
            </div>
          </div>
        </footer> */}
        <FooterTwo />
        {/* <!-- footer area end --> */}

        {/* <!-- back to top area start --> */}
        <div class="back-to-top">
          <span class="back-top">
            <i class="fa fa-angle-up"></i>
          </span>
        </div>
      </div>
    </div>
  );
};

export default SapHCM;

{
  /*   <div class="row d-flex justify-content-center align-items-center flex-wrap ">
          <div
            class="ebooks mt-4 col-lg-6 col-12 order-lg-1 order-5 d-flex   align-items-center wow animated fadeInLeft"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            {" "}
            <div class="section-title style-two mt-lg-0 mt-3">
              <h2 class="title">
                About <span>HR Pundits</span>
              </h2>
              <p>
                HR Pundits is formed by a highly experienced group of HR
                professionals specializing in delivering SAP HCM and
                SuccessFactors solutions. The company operates under the
                leadership of Krishna K. Reddy, CEO.
              </p>
              <p>
                Mr. Reddy has over 22 years of extensive HR domain experience,
                including 11 years as an SAP consultant, where he has
                architected and implemented SAP HCM and SuccessFactors solutions
                for Fortune 100 clients.
              </p>
              <!--<a href="#Recruitment-Services" class="explore-btn text-white d-flex justify-content-center align-items-center mt-4">Let's Connect </a>--> 
              </div>
              </div>
              <div class="col-lg-6 col-12 text-center  order-lg-5 order-1 ">
                <div class="company-img ">
                  {" "}
                  <div
                    class=" wow animated fadeInRight"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <img
                      class="object-fit-contain w-100"
                      src={Image1}
                      alt="img"
                    />
                    <p class="m-0 p-0">
                      <strong>Mr. Krishna K. Reddy, CEO</strong>
                      <br /> Over 22 years of HR domain experience
                    </p>
                  </div>
                </div>
              </div>
            </div>*/
}
