import React from "react";
import "./PublisingSolutions.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import FooterTwo from "../Footer2/FooterTwo";
import power from "../../assets/img/client/Prowess-partner.png";
import eBook from "../../assets/img/client/Ebooks2go-partner.jpg";
import gantech from "../../assets/img/client/Gantech-partner.jpg";

const PublisingSolutions = () => {
  //     const [animationPaused, setAnimationPaused] = useState(false);
  //   const slideTrackRef = useRef(null);

  //   const pauseSlider = () => {
  //     if (slideTrackRef.current) {
  //       slideTrackRef.current.style.animationPlayState = "paused";
  //       setAnimationPaused(true);
  //     }
  //   };

  //   const resumeSlider = () => {
  //     if (animationPaused && slideTrackRef.current) {
  //       slideTrackRef.current.style.animationPlayState = "running";
  //       setAnimationPaused(false);
  //     }
  //   };

  return (
    <div>
      {/* <!-- preloader area start --> */}
      {/* <div className="preloader" id="preloader">
      <div className="preloader-inner">
        <div className="spinner">
          <div className="dot1"></div>
          <div className="dot2"></div>
        </div>
      </div>
    </div> */}
      {/* <!-- preloader area end -->

    <!-- search Popup --> */}
      <div className="body-overlay" id="body-overlay"></div>
      <div className="search-popup" id="search-popup">
        <form
          action="http://zwin.io/html/riyaqas/index.html"
          className="search-form"
        >
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Search....."
            />
          </div>
          <button type="submit" className="submit-btn">
            <i className="fa fa-search"></i>
          </button>
        </form>
      </div>
      {/* <!-- //. search Popup -->

    <!-- navbar area start --> */}
      <Navbar />
      {/* <!-- navbar area end --> */}

      <div className="container Publishing-Sol-content">
        <div className="section-title style-two mt-lg-0 mt-5">
          <h2 className="title text-center">
            Our <span>Associate</span> companies
          </h2>
        </div>
        <div className="d-flex justify-content-around flex-wrap">
          <div
            className="ebooks col-lg-6 col-12 order-lg-1 order-5 d-flex align-items-end wow animated fadeInLeft"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            <div className="section-title style-two mt-lg-0 mt-3 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
              <h2 className="title text-lg-start text-center text-justify">
                e<span>Books</span>2go
              </h2>
              <p className="col-12 p-0 m-0 mt-2 text-lg-start">
                eBooks2go.com is an online eBook retail store promoted by
                eBooks2go, Inc., providing a robust platform for independent
                authors and publishers to upload and sell their books to readers
                worldwide. Our store offers multiple reading options, allowing
                readers to download books to their computers, mobile devices, or
                tablets in ePUB format. With partnerships with leading wholesale
                distributors and publishers, we feature a large selection of
                bestselling and new release titles, catering to a diverse range
                of customers. At eBooks2go, prices are set by the authors and
                publishers, ensuring competitive rates that are lower than
                printed versions. We aim to provide a simple and easy buying
                experience and welcome feedback to continually enhance our
                service. Explore our extensive collection and enjoy seamless
                eBook shopping at eBooks2go.com.
              </p>

              <a
                href="https://www.ebooks2go.net/"
                className="explore-btn text-white d-flex justify-content-center align-items-center px-4"
                target="_blank"
              >
                Explore
              </a>
            </div>
          </div>

          {/* <!--target="_blank"--> */}

          <div className="col-lg-6 col-12 order-lg-5 order-1">
            <div className="company-img">
              <div
                className="wow animated fadeInRight"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <img
                  className="object-fit-contain p-4 w-100"
                  src={eBook}
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-around flex-wrap mt-5">
          <div className="col-lg-6 col-12">
            <div className="company-img">
              <div
                className="wow animated fadeInRight"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <img
                  className="object-fit-fill w-100 p-0 m-0"
                  src={gantech}
                  alt="img"
                />
              </div>
            </div>
          </div>

          <div
            className="ebooks col-lg-6 col-12 d-flex align-items-end wow animated fadeInLeft"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            <div className="section-title style-two mt-lg-0 mt-3 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
              <h2 className="title text-lg-start text-center">
                <span>Gantec</span> Publishing solutions
              </h2>
              <p className="col-12 p-0 m-0 mt-2 col-lg-11 col-12 text-start text-justify">
                Established in 2008, Gantec Publishing Solutions partners with
                leading publishers worldwide to deliver innovative solutions
                that cut production costs, expedite new product development, and
                monetize legacy content. By leveraging our expertise in eMedia
                and technology, we help clients navigate the rapidly changing
                media landscape, opening new revenue streams through
                globalization and electronic distribution. Our comprehensive
                services, ranging from technology development to digital
                publishing, are designed to seamlessly integrate into your
                organizational strategy. Explore our website to discover how
                Gantec Publishing Solutions can transform challenges into
                opportunities and drive your business growth in the digital age.
              </p>

              <a
                href="https://www.gantecpublishing.com"
                className="explore-btn text-white d-flex justify-content-center align-items-center px-4"
                target="_blank"
              >
                Explore
              </a>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-around flex-wrap mt-5">
          <div
            className="ebooks col-lg-6 col-12 order-lg-1 order-5 d-flex align-items-end wow animated fadeInLeft"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            <div className="section-title style-two mt-lg-0 mt-3 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
              <h2 className="title text-lg-start text-center">
                <span>Prowess</span> Publishing solutions
              </h2>
              <p className="col-11 p-0 m-0 mt-2 col-12">
                Established as a division of US-based Prowess Technologies, LLC,
                Prowess Publishing brings over 14 years of experience in
                delivering innovative solutions to the publishing and media
                industries globally. Having supported hundreds of authors in the
                US since 2011, we now extend our expertise to the
                self-publishing community in India. Prowess, formed by Gantec
                Publishing Solutions, provides comprehensive services that
                enable Indian authors and publishers to thrive in the global
                market. In response to the digital evolution of the early 2000s,
                we offer expert eBook conversions and distribution through major
                retailers like Amazon, Apple, and Barnes & Noble, as well as
                through our partner eBooks2go. Our services also include
                marketing assistance, cover design, monthly sales reports, and
                ISBN retrieval, ensuring a seamless publishing experience from
                manuscript submission to worldwide distribution.
              </p>

              <a
                href="https://www.prowesspub.com"
                className="explore-btn text-white d-flex justify-content-center align-items-center px-4"
                target="_blank"
              >
                Explore
              </a>
            </div>
          </div>
          <div className="col-lg-6 col-12 order-lg-5 order-1">
            <div className="company-img">
              <div
                className="wow animated fadeInRight"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <img
                  className="object-fit-contain p-4 w-100"
                  src={power}
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!--    <div className=" d-flex justify-content-around flex-wrap mt-5">-->
    <!--<div className="ebooks col-lg-6 col-12 order-lg-1 order-5 d-flex  align-items-end wow animated fadeInLeft" data-wow-duration="1s" data-wow-delay="0.3s"> <div className="section-title style-two mt-lg-0 mt-3">-->
    <!--    <h2 className="title"><span>Prowess</span> Publishing solutions</h2>-->
    <!--    <p className="col-11 p-0 m-0 mt-2">Established as a division of US-based Prowess Technologies, LLC, Prowess Publishing has over 14 years of experience delivering innovative solutions to publishing and media companies worldwide. Since 2011, we have helped hundreds of US authors launch their careers and now bring the same expertise to the self-publishing community in India. Formed by Gantec Publishing Solutions, Prowess offers a multitude of services to help Indian authors succeed globally. Our unique approach supports authors at every stage, from manuscript submission to distribution, specializing in eBook conversions and offering marketing assistance, cover design, and distribution through top retailers like Amazon and Apple.</p>-->

    <!--     <a href="https://www.prowesspub.com" className="explore-btn text-white d-flex justify-content-center align-items-center">Explore <i className="fa fa-car"></i></a>-->
    <!--</div> </div>-->
    <!--<div className="col-lg-6 col-12  order-lg-5 order-1 ">-->
    <!--    <div className="company-img "> <div className=" wow animated fadeInRight" data-wow-duration="1s" data-wow-delay="0.3s">-->
    <!--        <img className="object-fit-contain p-4 w-100" src="assets/img/client/Prowess-partner.png" alt="img">-->
    <!--    </div></div>-->
    <!--</div>--> */}

      <FooterTwo />

      <div className="back-to-top">
        <span className="back-top">
          <i className="fa fa-angle-up"></i>
        </span>
      </div>
    </div>
  );
};

export default PublisingSolutions;
