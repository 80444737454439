import React, { useState, useEffect } from "react";
import "./ContactUs.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import PageTitleBg from "../../assets/img/page-title-bg.png";
import blog from "../../assets/img/others/21.png";
import FooterTwo from "../Footer2/FooterTwo";
import axios from "axios";
import { Toaster, toast } from "react-hot-toast";

const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState({
    name: "",
    email: "",
    message: "",
    subject: "",
  });
  const validateForm = () => {
    const errors = {};

    if (name.trim() === "") {
      errors.name = "Name is required";
    }
    if (!email.trim()) {
      errors.email = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      errors.email = "Invalid email format";
    }
    if (!subject.trim()) {
      errors.subject = "Subject is required";
    }
    if (!message.trim()) {
      errors.message = "Message is required";
    }

    setError(errors);

    return Object.keys(errors).length === 0; // Returns true if no errors
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    validateForm();
    try {
      if (validateForm()) {
        const payload = {
          name: name,
          email: email,
          subject: subject,
          message: message,
        };
        const { data } = await axios.post(
          `${process.env.REACT_APP_SERVER}/api/email/contactUs`,
          payload
        );
        console.log(data);
        toast.success("Form Submitted");
        setLoader(false);
      } else {
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);

      if (error?.response?.data) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong");
      }
    } finally {
      setName("");
      setEmail("");
      setSubject("");
      setMessage("");
    }
  };
  console.log(error);

  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />

      <div class="body-overlay" id="body-overlay"></div>
      <div class="search-popup" id="search-popup">
        <form class="search-form">
          <div class="form-group">
            <input type="text" class="form-control" placeholder="Search....." />
          </div>
          <button type="submit" class="submit-btn">
            <i class="fa fa-search"></i>
          </button>
        </form>
      </div>

      <Navbar />

      <div
        class="breadcrumb-area"
        // style="background-image:url(assets/img/page-title-bg.png);"
        style={{
          backgroundImage: `url(${PageTitleBg})`,
        }}
      >
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="breadcrumb-inner">
                <h1 class="page-title">Contact</h1>
                <ul class="page-list">
                  <li>+1 (847) 598-1122</li>
                  <li>info@hrpundits.com</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="contact-form-area pd-top-112">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-6 col-lg-8">
              <div class="section-title text-center w-100">
                <h2 class="title">
                  Send your <span>Enquiry</span>
                </h2>
                <p>
                  Have any questions or need more information? Fill out the form
                  below, and our team will get back to you as soon as possible.
                </p>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-xl-4 col-lg-5">
              <img src={blog} alt="blog" />
            </div>
            <div class="col-lg-7 offset-xl-1">
              <form
                id="inquiryForm"
                class="riyaqas-form-wrap mt-5 mt-lg-0"
                onSubmit={handleSubmit}
              >
                <div class="row custom-gutters-16">
                  <div class="col-md-6">
                    <div class="single-input-wrap">
                      <input
                        type="text"
                        id="name"
                        class="single-input"
                        // required
                        value={name}
                        placeholder="Name"
                        onChange={(e) => {
                          setName(e.target.value);
                          setError((prev) => ({ ...prev, name: "" }));
                        }}
                      />

                      <span style={{ color: "red" }}>{error.name}</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="single-input-wrap">
                      <input
                        type="email"
                        id="email"
                        class="single-input"
                        // required
                        placeholder="E-mail"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setError((prev) => ({ ...prev, email: "" }));
                        }}
                      />

                      <span style={{ color: "red" }}>{error.email}</span>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="single-input-wrap">
                      <input
                        type="text"
                        id="subject"
                        class="single-input"
                        value={subject}
                        placeholder="Subject"
                        onChange={(e) => {
                          setSubject(e.target.value);
                          setError((prev) => ({ ...prev, subject: "" }));
                        }}
                        // required
                      />

                      <span style={{ color: "red" }}>{error.subject}</span>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="single-input-wrap">
                      <textarea
                        class="single-input textarea"
                        id="message"
                        cols="20"
                        // required
                        placeholder="Message"
                        value={message}
                        onChange={(e) => {
                          setMessage(e.target.value);
                          setError((prev) => ({ ...prev, message: "" }));
                        }}
                      ></textarea>

                      <span style={{ color: "red" }}>{error.message}</span>
                    </div>
                  </div>
                  <div class="col-12">
                    <button
                      id="submitButton"
                      type="submit"
                      class="btn btn-red mt-0"
                      disabled={loader}
                    >
                      {loader ? "Sending..." : "Send"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="map-area pd-top-120">
        <div class="container">
          <div class="row custom-gutters-20 justify-content-around">
            <div class="col-lg-5 col-12">
              <div class="single-service-2 text-left">
                <h6>United States Office:</h6>
                <p>
                  Administrative Office :<br />
                  HR Pundits Inc.
                  <br />
                  1827 Walden office Sq Suite # 260 Schaumburg, IL 60173
                  <br />
                  Phone : +1 (847) 598-1122
                  <br />
                  Email : info@hrpundits.com
                  <br />
                </p>
              </div>
            </div>
            {/* <div class="col-lg-3 col-sm-6">
              <div class="single-service-2 text-left">
                <h6>United States Offices:</h6>
                <p>
                  Registered Office:
                  <br />
                  HR Pundits Inc.
                  <br />
                  4855,Emerson Ave.
                  <br />
                  Rolling Meadows,
                  <br />
                  Illinois– 60008
                  <br />
                  Phone No: 847 598 1122
                  <br />
                  Contact : info@hrpundits.com
                </p>
              </div>
            </div> */}
            <div class="single-service-2 col-lg-6 col-12">
              {" "}
              <h6>Asia Offices:</h6>
              <div class=" row  col-12   p-0 m-0">
                <div class=" col-lg-6">
                  <p class=" ">
                    Hyderabad Office
                    <br />
                    3-6-475/3/B1, First Floor,
                    <br />
                    Road No - 3, HimayatNagar,
                    <br />
                    Hyderabad - 500 034
                    <br />
                    Phone :+91 84759 81122
                    <br />
                    Email : info@hrpundits.com
                  </p>
                </div>
                <div class=" col-6 ">
                  {/* <h6>Asia Offices:</h6> */}
                  <p>
                    Chennai Office
                    <br />
                    No: 21/36, YRK Towers, 2nd floor,
                    <br />
                    Thadikara Swamy Koil Street, Alandur,
                    <br />
                    Guindy, Chennai - 600-016
                    <br />
                    Phone :+91 98844 79531
                    <br />
                    Email : info@prowesspub.com
                  </p>
                </div>
              </div>
            </div>
            {/* <div class="col-lg-3 col-sm-6">
              <div class="single-service-2 text-left">
                <h6>Asia Offices:</h6>
                <p>
                  Singapore office
                  <br />
                  Block 673, No. 10 – 13
                  <br />
                  Woodlands Drive–71,
                  <br />
                  Singapore, 730673
                  <br />
                  Phone : (65) 64052865
                  <br />
                  Contact : info@hrpundits.com
                </p>
              </div>
            </div> */}
          </div>
        </div>

        <FooterTwo />

        <div class="back-to-top">
          <span class="back-top">
            <i class="fa fa-angle-up"></i>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
