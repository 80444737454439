import React, { useEffect } from "react";
import "./Home.css";
import logo from "../../images/logo.png";
import image1 from "../../assets/img/we-provide/5.png";
import image2 from "../../assets/img/others/map.png";
import image3 from "../../assets/img/business-tool/1.png";
import image4 from "../../assets/img/business-tool/pencil.svg";
import image5 from "../../assets/img/business-tool/search.svg";
import image6 from "../../assets/img/business-tool/settings.svg";
import image7 from "../../assets/img/we-provide/1.png";
import image8 from "../../assets/img/we-provide/3.svg";
import image9 from "../../assets/img/icons/9.svg";
import image10 from "../../assets/img/icons/10.svg";
import image11 from "../../assets/img/icons/11.svg";
import image12 from "../../assets/img/icons/12.svg";
import image13 from "../../assets/img/client/Accenture.png";
import image14 from "../../assets/img/client/Tech Mahindra.png";
import Astellas from "../../assets/img/client/Astellas.png";
import Paradigm from "../../assets/img/client/Paradigm.png";
import Thyssenkrupp from "../../assets/img/client/Thyssenkrupp.png";
import Carpenter from "../../assets/img/client/Carpenter.png";
import FenwalBlood from "../../assets/img/client/FenwalBlood.png";
import Shure from "../../assets/img/client/Shure.png";
import Enterasys from "../../assets/img/client/Enterasys.png";
import MitsubishiElectric from "../../assets/img/client/Mitsubishi Electric.png";
import NavajoTribalUtilityAuthority from "../../assets/img/client/Navajo Tribal Utility Authority.png";
import Leprino from "../../assets/img/client/Leprino.png";
import IGT from "../../assets/img/client/IGT.png";
import OHair from "../../assets/img/client/O’Hair.png";
import FMC from "../../assets/img/client/FMC.png";
import Waupaca from "../../assets/img/client/Waupaca.png";
import ULTA from "../../assets/img/client/ULTA salon.png";
import TechMahindra from "../../assets/img/client/Tech Mahindra.png";
import Accenture from "../../assets/img/client/Accenture.png";
import bgImage from "../../assets/img/bg/1h1.png";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import WOW from 'wowjs';
import 'animate.css';
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

const Home = () => {
  useEffect(() => {
    const wow = new WOW.WOW({ live: false });
    wow.init();
  }, []);

  const { ref, inView } = useInView({
    triggerOnce: true, // Ensures the animation triggers only once
    threshold: 0.5, // Triggers when 50% of the element is visible
  });
  return (
    <div>
      {/* <div className="preloader " id="preloader">
        <div className="preloader-inner">
          <div className="spinner">
            <div className="dot1"></div>
            <div className="dot2"></div>
          </div>
        </div>
      </div> */}

      <div className="body-overlay" id="body-overlay"></div>
      <div className="search-popup" id="search-popup">
        <form
          action="http://zwin.io/html/riyaqas/index.html"
          className="search-form"
        >
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Search....."
            />
          </div>
          <button type="submit" className="submit-btn">
            <i className="fa fa-search"></i>
          </button>
        </form>
      </div>

      {/* <nav className="navbar navbar-area navbar-expand-lg nav-style-01">
        <div className="container nav-container">
          <div className="responsive-mobile-menu">
            <div className="logo-wrapper mobile-logo">
              <a href="#" className="logo">
                <img src={logo} alt="logo" />
              </a>
            </div>

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#Riyaqas_main_menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggle-icon">
                <span className="line"></span>
                <span className="line"></span>
                <span className="line"></span>
              </span>
            </button>
          </div>
          <div className="collapse navbar-collapse" id="Riyaqas_main_menu">
            <div className="logo-wrapper desktop-logo">
              <a href="#" className="logo">
                <img src={logo} alt="logo" />
              </a>
            </div>
            <ul className="navbar-nav ">
              <li>
                <a href="/" className="active">
                  Home
                </a>
              </li>
              <li className="menu-item-has-children">
                <a href="#">Talent Management</a>
                <ul className="sub-menu">
                  <li>
                    <a href="Recruitment_Services.html">
                      Recruitment Services{" "}
                    </a>
                  </li>
                  <li>
                    <a href="/softSkills">Soft Skills</a>
                  </li>
                  <li>
                    <a href="#">HR Services</a>
                  </li>
                </ul>
              </li>

              <li className="menu-item-has-children">
                <a href="#">Application Development</a>
                <ul className="sub-menu">
                  <li>
                    <a href="#">Websites / Mobile APPS</a>
                  </li>

                  <li>
                    <a href="#">Software Development</a>
                  </li>
                </ul>
              </li>

              <li>
                <a href="/jobPosting">Job Postings</a>
              </li>

              <li className="menu-item-has-children">
                <a href="#">Other Services</a>

                <ul className="sub-menu">
                  <li className="menu-item-has-children">
                    <a href="#">Training</a>
                    <ul className="sub-menu">
                      <li>
                        <a href="#">Corporate Training</a>
                      </li>
                      <li>
                        <a href="/sapHCM">SAP HCM & SuccessFactor</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="/publishingSolutions">Publishing Solutions</a>
                  </li>
                  <li>
                    <a href="#">Media</a>
                  </li>
                  <li>
                    <a href="#">Community Services</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="/contact">Contact Us</a>
              </li>
            </ul>
          </div>
        </div>
      </nav> */}
      <Navbar />

      <div className="header">
        <div className="header-content text-start">
          <h2 className="title text-white text-lg-start text-md-center">
            <span className="welcome">Welcome to</span> HR Pundits{" "}
          </h2>
          <p className="col-lg-12  text-lg-start text-md-center text-white">
            " Our success mirrors the lotus flower, emerging from challenges to
            bloom in full glory, representing purity, resilience, and
            transformation. "
          </p>
        </div>
      </div>

      {/* <div
        className="sbs-what-riyaqas pd-default-120 "
        // style={{ backgroundImage: `url(${bgImage})` }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 wow animated fadeInRight"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              <img src={image1} alt="img" />
            </div>
            <div className="col-xl-5 col-lg-6 desktop-center-item">
              <div
                className="desktop-center-area wow animated fadeInLeft"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <div className="section-title style-two">
                  <h2 className="title">
                    What is <span>HR Pundits?</span>
                  </h2>
                  <p className="">
                    The word 'Pundits' in Sanskrit means the people who are
                    experts. Our company has been established in 2005 by a group
                    of SAP HCM experts with the sole aim of providing SAP HCM
                    solutions at the global level. Currently our consultants are
                    providing services in USA, Canada and Australia. Our
                    workforce is experienced in providing end-to-end solutions -
                    Business Requirement Analysis, Cost analysis and project
                    execution. HR Pundits's uses best practices approach with
                    customized templates for ease of implementations. It is our
                    policy that all the consultants are certified in the
                    respective areas and always have upgraded skills. Our
                    workforce interacts frequently to share their knowledge with
                    fellow team members. We are proud of our dedicated and
                    talented people who are working hard for the success of our
                    company HR Pundits, Inc.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="sbs-what-riyaqas pd-default-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 fade-in-right">
              <img src={image1} alt="img" />
            </div>
            <div className="col-xl-5 col-lg-6 desktop-center-item">
              <div className="desktop-center-area fade-in-left">
                <div className="section-title style-two">
                  <h3 className="title">
                    What is <span>HR Pundits?</span>
                  </h3>
                  <p>
                    The word 'Pundits' in Sanskrit means the people who are
                    experts. Our company has been established in 2005 by a group
                    of SAP HCM experts with the sole aim of providing SAP HCM
                    solutions at the global level. Currently our consultants are
                    providing services in USA, Canada and Australia. Our
                    workforce is experienced in providing end-to-end solutions -
                    Business Requirement Analysis, Cost analysis and project
                    execution. HR Pundits's uses best practices approach with
                    customized templates for ease of implementations. It is our
                    policy that all the consultants are certified in the
                    respective areas and always have upgraded skills. Our
                    workforce interacts frequently to share their knowledge with
                    fellow team members. We are proud of our dedicated and
                    talented people who are working hard for the success of our
                    company HR Pundits, Inc.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

{   /*   <div className="sbs-talented-people mg-top-120">
      <div className="container">
        <div className="row">
          <div className="col-xl-5 col-lg-6 offset-xl-1 desktop-center-item">
            <div className="desktop-center-area">
              <div className="section-title style-three">
                <h2 className="title">300+ Talented People</h2>
                <p>
                  Our support team will get assistance from AI-powered
                  suggestions, making it quicker than ever to handle support
                  requests.
                </p>
              </div>
              <div className="talented-pepole-list">
  <ul ref={ref}>
    <li>
      <h3 className={`count-num ${inView ? "in-view" : ""}`}>
        {inView && <CountUp start={0} end={30} duration={2} />}
      </h3>
      <span>UI/UX Designer</span>
    </li>
    <li>
      <h3 className={`count-num ${inView ? "in-view" : ""}`}>
        {inView && <CountUp start={0} end={228} duration={2} />}
      </h3>
      <span>Developer</span>
    </li>
    <li>
      <h3 className={`count-num ${inView ? "in-view" : ""}`}>
        {inView && <CountUp start={0} end={8} duration={2} />}
      </h3>
      <span>Architect</span>
    </li>
    <li>
      <h3 className={`count-num ${inView ? "in-view" : ""}`}>
        {inView && <CountUp start={0} end={30} duration={2} />}
      </h3>
      <span>Project Manager</span>
    </li>
  </ul>
</div>

            </div>
          </div>
          <div className="col-lg-6">
            <div className="world-map-wrap">
              <img src={image2} alt="map" />
            </div>
          </div>
        </div>
      </div>
    </div>

      <div
        className="leftside-image-area service-area sbs-business-tool pd-bottom-120 mg-top-120"
        style={{ backgroundImage: `url('../../assets/img/bg/1h2.png')` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-xl-7 fade-in-right">
              <img src={image3} alt="img" />
            </div>
            <div className="col-xl-5 col-lg-6 desktop-center-item">
              <div className="desktop-center-area fade-in-left">
                <div className="section-title style-two">
                  <h3 className="title">
                    All kind of{" "}
                    <span>
                      Business <br /> tools
                    </span>{" "}
                    integration.
                  </h3>
                  <p>
                    Our support team will get assistance from AI-powered
                    suggestions,
                   making it quicker than ever to handle support
                    requests.
                  </p>
                </div>
                <div className="row">
                  <div className="col-lg-9">
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={image4} alt="check" />
                      <span>
                        Our support team will get assistance from AI-powered
                        suggestions, making it quicker than.
                      </span>
                    </div>
                    <div className="riyaqas-check-list media">
                      <img className="media-left" src={image5} alt="check" />
                      <span>
                        Our support team will get assistance from AI-powered
                        suggestions.
                      </span>
                    </div>
                    <div className="riyaqas-check-list media mg-bottom-0-991">
                      <img className="media-left" src={image6} alt="check" />
                      <span>
                        Our support team will get assistance from AI-powered,
                        making it quicker than ever to handle support.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sbs-provide-security pd-top-120">
      <div className="container">
        <div className="row justify-content-center">
        <div
            className="col-xl-6 col-lg-6 order-lg-12 wow animated fadeInLeft"
          >
            <div className="img-with-video">
              <div className="img-wrap">
                <img src={image7} alt="video" />
                <div className="hover">
                  <a href="#" className="video-play-btn mfp-iframe">
                    <img src={image8} alt="video" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xl-5 col-lg-6 order-lg-1 align-self-center wow animated fadeInRight"
          >
            <div className="section-title style-two">
              <h2 className="title">
                How We Providing <br /> Strong <span>Security</span>
              </h2>
              <p>
                Our support team will get assistance from AI-powered
                suggestions, making it quicker than ever to handle requests to
                handle suggestions assistance it requests.
              </p>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="riyaqas-check-list">
                  <img className="media-left" src={image9} alt="check" />
                  <span>Unique Design</span>
                </div>
                <div className="riyaqas-check-list">
                  <img className="media-left" src={image10} alt="check" />
                  <span>Custom domain</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="riyaqas-check-list">
                  <img className="media-left" src={image11} alt="check" />
                  <span>Mobile ready</span>
                </div>
                <div className="riyaqas-check-list">
                  <img className="media-left" src={image12} alt="check" />
                  <span>Testing work</span>
                </div>
              </div>
            </div>
          </div>
         
        </div>
      </div>
    </div>*/}

      <div className="client-area mg-top-100 bg-gray pd-top-110 pd-bottom-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9">
              <div className="section-title style-two text-md-center">
                <h2 className="title">
                  Our valuable <span>Clients</span>
                </h2>
                <p>
                
                </p>
              </div>
            </div>
          </div>

          <div className="container scroll-container d-flex justify-content-center align-items-center ">
            <div
              className="slider mt-2"
              onmouseover="pauseSlider()"
              onmouseout="resumeSlider()"
            >
              <div
                className="slide-track d-flex justify-content-around align-items-center"
                id="slideTrack"
              >
                <div className="slide">
                  <img src={image13} alt="Author Image" />
                </div>
                <div className="slide">
                  <img src={image14} alt="Author Image" />
                </div>
                <div className="slide">
                  <img src={Astellas} alt="Author Image" />
                </div>
                <div className="slide">
                  <img src={Paradigm} alt="Author Image" />
                </div>
                <div className="slide">
                  <img src={Thyssenkrupp} alt="Author Image" />
                </div>
                <div className="slide">
                  <img src={Carpenter} alt="Author Image" />
                </div>
                <div className="slide">
                  <img src={FenwalBlood} alt="Author Image" />
                </div>
                <div className="slide">
                  <img src={Shure} alt="Author Image" />
                </div>
                <div className="slide">
                  <img src={Enterasys} alt="Author Image" />
                </div>
                <div className="slide">
                  <img src={MitsubishiElectric} alt="Author Image" />
                </div>
                <div className="slide">
                  <img src={NavajoTribalUtilityAuthority} alt="Author Image" />
                </div>
                <div className="slide">
                  <img src={Leprino} alt="Author Image" />
                </div>
                <div className="slide">
                  <img src={IGT} alt="Author Image" />
                </div>
                <div className="slide">
                  <img src={OHair} alt="Author Image" />
                </div>
                <div className="slide">
                  <img src={FMC} alt="Author Image" />
                </div>
                <div className="slide">
                  <img src={Waupaca} alt="Author Image" />
                </div>
                <div className="slide">
                  <img src={ULTA} alt="Author Image" />
                </div>
                <div className="slide">
                  <img src={TechMahindra} alt="Author Image" />
                </div>
                <div className="slide">
                  <img src={Accenture} alt="Author Image" />
                </div>
                <div className="slide">
                  <img src={Astellas} alt="Author Image" />
                </div>
                <div className="slide">
                  <img src={Paradigm} alt="Author Image" />
                </div>
                <div className="slide">
                  <img src={Thyssenkrupp} alt="Author Image" />
                </div>
              </div>
            </div>
          </div>

          {/* <div className="row justify-content-center">
            <div className="col-xl-10">
              <div className="client-slider-2">
                <div className="item">
                  <a href="#" className="thumb">
                    <img src="assets/img/startup/client/1.png" alt="client" />
                  </a>
                </div>
                <div className="item">
                  <a href="#" className="thumb">
                    <img src="assets/img/startup/client/2.png" alt="client" />
                  </a>
                </div>
                <div className="item">
                  <a href="#" className="thumb">
                    <img src="assets/img/startup/client/3.png" alt="client" />
                  </a>
                </div>
                <div className="item">
                  <a href="#" className="thumb">
                    <img src="assets/img/startup/client/4.png" alt="client" />
                  </a>
                </div>
                <div className="item">
                  <a href="#" className="thumb">
                    <img src="assets/img/startup/client/5.png" alt="client" />
                  </a>
                </div>
                <div className="item">
                  <a href="#" className="thumb">
                    <img src="assets/img/startup/client/6.png" alt="client" />
                  </a>
                </div>

                <div className="item">
                  <a href="#" className="thumb">
                    <img src="assets/img/startup/client/1.png" alt="client" />
                  </a>
                </div>
                <div className="item">
                  <a href="#" className="thumb">
                    <img src="assets/img/startup/client/5.png" alt="client" />
                  </a>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      {/* <footer className="footer-area footer-area-2 mg-top-105">
        <div className="container">
          <div className="footer-widget-area mg-top-120">
            <div className="row d-flex justify-content-around ">
              <div className="col-lg-3 col-md-6">
                <div className="footer-widget widget">
                  <div className="about_us_widget">
                    <a href="#" className="footer-logo">
                      <img src="images/logo.png" alt="footer logo" />
                    </a>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy.
                    </p>
                    <ul className="social-icon">
                      <li>
                        <a className="facebook" href="#" target="_blank">
                          <i className="fa fa-facebook  "></i>
                        </a>
                      </li>
                      <li>
                        <a className="twitter" href="#" target="_blank">
                          <i className="fa fa-twitter  "></i>
                        </a>
                      </li>
                      <li>
                        <a className="linkedin" href="#" target="_blank">
                          <i className="fa fa-linkedin"></i>
                        </a>
                      </li>
                      <li>
                        <a className="pinterest" href="#" target="_blank">
                          <i className="fa fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6">
                <div className="footer-widget widget ">
                  <h4 className="widget-title">Contact</h4>
                  <div className="contact_info_list">
                    <p className="contact-content">
                      1827 Walden office Sq Suite # 260 Schaumburg, IL- 60173.
                    </p>
                    <p>
                      <span>Contact:</span> 847 598 1122
                    </p>
                    <p>
                      <span>E-mail:</span> info@hrpundits.com
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6">
                <div className="footer-widget widget widget_nav_menu">
                  <h4 className="widget-title">Quick Link</h4>
                  <ul>
                    <li>
                      <a href="#">Talent Management</a>
                    </li>
                    <li>
                      <a href="#">Application Development</a>
                    </li>
                    <li>
                      <a href="#">ERP</a>
                    </li>
                    <li>
                      <a href="#">Training</a>
                    </li>
                    <li>
                      <a href="#">Corporate Training</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="footer-widget widget">
                  <h4 className="widget-title">Latest News</h4>
                  <div className="about_recent_post">
                    <div className="media">
                      <img src="assets/img/blog/widget1.png" alt="post" />
                      <div className="media-body">
                        <h6>
                          <a href="#">New Feature Added</a>
                        </h6>
                        <span>21 August 2019</span>
                      </div>
                    </div>
                    <div className="media">
                      <img src="assets/img/blog/widget2.png" alt="post" />
                      <div className="media-body">
                        <h6>
                          <a href="#">Business Development</a>
                        </h6>
                        <span>21 August 2019</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-inner">
          <div className="copyright-text">
            Copyright © 2023. HR Pundits. All rights reserved.
          </div>
        </div>
      </footer> */}
      <Footer />

      <div className="back-to-top">
        <span className="back-top">
          <i className="fa fa-angle-up"></i>
        </span>
      </div>
    </div>
  );
};

export default Home;
